.title {
  font-weight: 800;
  font-size: 20px;
}
.subtitle {
  font-size: 14px;
  font-weight: 700;
}
.details {
  font-size: 12px;
  font-weight: 400;
}

.theme-light {
  .bgc-white {
    background-color: $white-color;
  }
  .text-color-dark {
    color: $black-color;
  }
  .details-text {
    color: $gray-color;
  }
  .card-color {
    background-color: $lightest-gray-color;
    border-color: $dark-white-color !important;
  }
}

.theme-dark {
  .bgc-white {
    background-color: $black-color !important;
  }
  .text-color-dark {
    color: $white-color !important;
  }
  .details-text {
    color: $dim-gray-color;
  }
  .card-color {
    background-color: $lighter-black-color;
    border-color: $light-black-color !important;
  }
  .bgc-lighter-black {
    background-color: $lighter-black-color !important;
  }
}
