.history-section {
  .history-details {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .history-collection {
    padding: 2rem;
  }
  .cardholder-section {
    display: flex;
    flex-direction: column;
  }
  .history-search {
    border: none !important;
    border-radius: 25rem;
    height: 38px;
    font-size: 14px;
  }
  .amount-details {
    display: flex;
    justify-content: space-between;
  }
}

.theme-dark {
  .history-section {
    .history-search {
      border: none !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .history-section {
    .responsive-historyCard {
      border: 1px solid #eeeeee;
      border-radius: 8px;
      .history-title {
        padding: 0 0 1.5rem 0 !important;
      }
    }
    .history-collection {
      padding: 1rem;
      border: 1px solid #eee;
      border-radius: 8px;
      .responsive-history-details {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
    }
  }

  .theme-dark {
    .history-section {
      .responsive-historyCard {
        border: 1px solid #242424;
      }
    }
    .history-collection {
      border: 1px solid #242424;
    }
  }
}
