.right-view {
    position: fixed;
    height: 100%;
    width: 50%;
    top: 0px;
    right: -50%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 24px;
    z-index: 999;

    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 0.2s;
    animation: slide 0.5s forwards;
    animation-delay: 0.2s;

    font-family: "HelveticaNowDisplay";

    .title-view {
        display: flex;
        width: 100%;

        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 32px;
        color: #111111;

        align-items: center;

        > div {
            margin-right: auto;
        }

        > span {
            margin-left: auto;
        }
    }
    
    .body-view {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }
}

@-webkit-keyframes slide {
    100% { right: 0; }
}

@keyframes slide {
    100% { right: 0; }
}

@media (max-width: 992px) {
    .right-view {
        width: 100% !important;
        height: 100%;
    }
}
.right-view{
.body-view::-webkit-scrollbar{
    display: none;
}}