.create-post-page {
    max-height: 90vh;
    box-sizing: border-box;
    .bread-crumb {
        .main-page {
            color: $gray-color;
        }
    }
    .create-post-card {
        border: 1px solid $dark-white-color;
        border-radius: 8px;
        @media (max-width: 576px) {
            height: 80vh;
        }
        .schedule-post-button {
            button {
                @media (max-width: 576px) {
                    width: 100%;
                    font-size: 16px !important;
                    padding: 0.75rem 0 !important;
                }
            }
        }
    }
}

.theme-dark {
    .create-post-page {
        .bread-crumb {
            .main-page {
                color: $dim-gray-color;
            }
        }
        .create-post-card {
            border: 1px solid $light-black-color;
        }
    }
}

@media screen and (max-width: 1024px) {
    .create-post-page {
        margin-top: 4rem;
        .bread-crumb {
            padding-top: 1rem;
        }
    }
}

@media screen and (max-width: 576px) {
    .create-post-page {
        .create-post-card {
            position: relative;
            .schedule-post-button {
                position: absolute;
                bottom: 1.5rem;
                left: 0;
                padding: 0 1.5rem;
            }
        }
    }
}
