.filter-tab {
    @media (min-width: 550px) {
        display: inline-flex;
    }

    height: 100%;
    align-items: center;
    gap: 20px;
    overflow-x:scroll;
    max-width:100%;

    .left-side {
        margin-right: auto;
        display: inline-flex;
        gap: 16px;

        font-family: "HelveticaNowDisplay";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #A3A3A3;
    }


    .right-side {
        white-space: nowrap;
        margin-left: auto;
        background: #F1F1F3;
        border-radius: 8px;
        height: 100%;
        min-width: 50px;
        font-family: "HelveticaNowDisplay";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #A3A3A3;
        display: inline-flex;
        align-items: center;
        height: 100%;
        padding: 4px;
        gap: 4px;

        .filter-element {
            height: 100%;
            align-items: center;
            display: flex;
            padding: 8px;
            cursor: pointer;

            &.active {
                background: #FFFFFF;
                border-radius: 4px;
                color: #111111;
            }
        }
    }
}

.min-earning {
    margin-right: auto;
    position: relative;
    top: 10px;
    gap: 16px;
    font-family: "HelveticaNowDisplay";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #A3A3A3;
}