.option-button-container {
    position: relative;
    .popup {
        width: max-content;
        position: absolute;
        top: 100%;
        right: 0;
        background-color: $white-color;
        border: 1px solid $dark-white-color;
        border-radius: 0.5rem;
        z-index: 999;
        .option {
            display: flex;
            i,
            div {
                color: $gray-color;
            }
        }
    }
}

.theme-dark {
    .option-button-container {
        .popup {
            background-color: $black-color;
            border: 1px solid $light-black-color;
            .option {
                i,
                div {
                    color: $dim-gray-color;
                }
            }
        }
    }
}
