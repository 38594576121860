.manage-section {
  .add-card {
    padding: 1rem;
    border: 1px solid;
    border-radius: 8px;
    margin: 1rem 2rem;
  }
  .page-title {
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 600px) {
  .manage-section {
    .add-card {
      padding: 0.5rem;
      margin: 1rem 0;
    }
    .page-title {
      padding: 1rem 0rem;
    }
  }
}
