.modal-card {
    min-height: 100px;
    width: 480px;
    position: absolute;
    top: 50%; right: 50%;
    transform: translate(50%,-50%);
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;
    z-index: 999;
    border-radius: 8px;

    font-family: "HelveticaNowDisplay";

    .header-view {
        display: flex;
        width: 100%;
        letter-spacing: -0.03em;
        flex-direction: column;
        gap: 4px;

        .title-view {
            color: #111111;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #111111;
        }
        
        .sub-title-view {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #A3A3A3; 
        }
    }

    .body-view {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;

        .input-card {
            textarea {
                width: 100%;
                height: 120px;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
                border-radius: 8px;
                padding: 8px 16px;

                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
            }
        }
        
        .sub-withdraw-amount {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #A3A3A3; 
        }

        .withdraw-amount {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h4 {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #A3A3A3; 
            }

            h2 {
                font-style: normal;
                font-weight: 800;
                font-size: 28px;
                line-height: 36px;
                color: #111111;
            }
        }

        .card-attachment {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #2E69FF;
        }

        .image-view {
            img {
                width: 100%;
            }
        }

        .link-view {
            ul {
                li, a {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.03em;
                    color: #111111;
                    margin-bottom: 5px;
                    display: inline-flex;
                    gap: 5px;
                    width: 100%;
                }
            }
        }
    }

    .footer-view {
        width: 100%;

        .footer-right-button {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 16px;
            justify-content: flex-end;
        
            span {
                font-family: "HelveticaNowDisplay";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.03em;
                color: #111111;
                cursor: pointer;
            }
        }

        .footer-left-button {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 16px;
            justify-content: flex-start;
        
            span {
                font-family: "HelveticaNowDisplay";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.03em;
                color: #111111;
            }
        }
    }
}


.modal-card-support {
    min-height: 100px;
    width: 100%;
   
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    z-index: 999;
    border-radius: 8px;

    font-family: "HelveticaNowDisplay";

    .header-view {
        display: flex;
        width: 100%;
        letter-spacing: -0.03em;
        flex-direction: column;
        gap: 4px;

        .title-view {
            color: #111111;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #111111;
        }
        
        .sub-title-view {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #A3A3A3; 
        }
    }

    .body-view {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;

        .input-card {
            textarea {
                width: 100%;
                height: 120px;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
                border-radius: 8px;
                padding: 8px 16px;

                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
            }
        }
        
        .sub-withdraw-amount {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #A3A3A3; 
        }

        .withdraw-amount {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h4 {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #A3A3A3; 
            }

            h2 {
                font-style: normal;
                font-weight: 800;
                font-size: 28px;
                line-height: 36px;
                color: #111111;
            }
        }

        .card-attachment {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #2E69FF;
        }

        .image-view {
            img {
                width: 100%;
            }
        }

        .link-view {
            ul {
                li {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.03em;
                    color: #111111;
                    margin-bottom: 5px;
                    display: inline-flex;
                    gap: 5px;
                }
            }
        }
    }

    .footer-view {
        width: 100%;

        .footer-right-button {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 16px;
            justify-content: flex-end;
        
            span {
                font-family: "HelveticaNowDisplay";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.03em;
                color: #111111;
            }
        }

        .footer-left-button {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 16px;
            justify-content: flex-start;
        
            span {
                font-family: "HelveticaNowDisplay";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.03em;
                color: #111111;
            }
        }
    }
}
