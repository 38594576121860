.payment-page {
    background-color: #fafafa;
    margin: 0;
    min-height: 100vh;
    padding: 1rem 0;
    .header {
        margin: 0 0 0 2rem;
        @media (max-width: 576px) {
            margin: 0 0 0 1.5rem;
        }
    }
    .main-section {
        width: 560px;
        margin: auto;
        @media (max-width: 576px) {
            margin: 0 1.5rem;
            width: unset;
        }
    }
    .payment-card {
        border: 1px solid $dark-white-color;
        padding: 1.5rem 2rem;
        border-radius: 8px;
        @media (max-width: 576px) {
            padding: 1rem;
        }
        .creator-info-container {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-top: 1.5rem;
            .creator-avatar {
                position: relative;
                width: 36px;
                height: 36px;
                margin-bottom: 0;
                border-radius: 100%;
                align-self: center;
            }
        }
        .payment-option {
            background: #fafafa;
            border: 1px solid #eeeeee;
            border-radius: 8px;
            justify-content: space-between;
            padding: 1rem;
            .payment-details {
                color: $gray-color;
                font-weight: 700;
                font-size: 14px;
            }
            .card-detail {
                display: flex;
                .card-logo {
                    margin-right: 1rem;
                }
                .card-number {
                    display: flex;
                    flex-direction: column;
                    .details {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }
        }
        .payment-option.cards {
            position: relative;
            padding: 0.5rem 1rem;
            .all-cards-dropdown {
                position: absolute;
                width: 100%;
                right: 0;
                background-color: #fafafa;
                border-radius: 0.5rem;
                border: 1px solid $dark-white-color;
                top: calc(100% + 4px);
                padding-bottom: 0.5rem;
                box-shadow: 0px 2px 16px 0px #0000001a;

                .card-datails-container {
                    margin: 0.75rem 0.5rem 0.25rem;
                    padding: 0.5rem;
                    border-radius: 0.5rem;
                    .payment-details {
                        color: $gray-color;
                        font-weight: 700;
                        font-size: 14px;
                    }
                    .card-detail {
                        display: flex;
                        .card-logo {
                            margin-right: 1rem;
                        }
                        .card-number {
                            display: flex;
                            flex-direction: column;
                            .details {
                                color: $gray-color;
                            }
                        }
                    }
                    .selected-icon {
                        visibility: hidden;
                    }
                }
                .card-datails-container.selected {
                    background-color: $dark-white-color;
                    .selected-icon {
                        visibility: visible;
                    }
                }
            }
        }
        .payment-option.active {
            border: 1px solid #cc0000;
            .payment-details {
                color: $black-color;
            }
        }
        .button-container {
            width: fit-content;
            .cancel-button,
            .confirm-button {
                font-size: 12px;
                @media (max-width: 576px) {
                    width: 100%;
                }
            }
            @media (max-width: 576px) {
                display: flex;
                width: unset;
                flex-direction: column-reverse;
                margin: 0;
            }
            .cancel-button {
                @media (max-width: 576px) {
                    font-size: 14px;
                }
            }
            .confirm-button {
                @media (max-width: 576px) {
                    font-size: 16px;
                }
            }
        }
    }
}

.theme-dark {
    .payment-page {
        background-color: #2b2b2b;
        .payment-card {
            border: 1px solid $light-black-color;
            .payment-option {
                background-color: #2b2b2b;
                border: 1px solid #242424;
                .payment-details {
                    color: $dim-gray-color;
                }
            }
            .payment-option.cards {
                .all-cards-dropdown {
                    background-color: #2b2b2b;
                    border: 1px solid $light-black-color;
                    box-shadow: 0px 2px 16px 0px #0000001a;
                    .card-datails-container {
                        .payment-details {
                            color: $dim-gray-color;
                        }
                        .card-detail {
                            .card-number {
                                .details {
                                    color: $dim-gray-color;
                                }
                            }
                        }
                    }

                    .card-datails-container.selected {
                        background-color: $light-black-color;
                    }
                }
            }
        }
        .payment-option.active {
            border: 1px solid #cc0000;
            .payment-details {
                color: $white-color;
            }
        }
    }
}
