.small-header-table {
    font-family: "HelveticaNowDisplay";
    font-style: normal;
    width: 100%;
    letter-spacing: -0.03em;

    h4 {
        
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #111111;

    }

    div {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #A3A3A3;
    }
}