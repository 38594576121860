.kpi-card {
    font-family: "HelveticaNowDisplay";
    letter-spacing: -0.03em;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    flex: 1;
    padding: 16px;

    h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #111111;
    }

    .kpi-value {
        padding-top: 24px;
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 44px;
        color: #111111;
    }

    .kpi-diff {
        padding-top: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #A3A3A3;
        display: flex;
        align-items: flex-end;

        span {
            margin-right: auto;
        }
    }

    .kpi-perc {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 8px;
        border-radius: 2px;   
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-left: auto;

        &.positive {
            color: #4ECC6A;
            background: #EDFAF0;
        }

        &.neutral {
            color: #F2994A;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F2994A;
        }

        &.negative {
            color: #CC0000;
            background: #FAE5E5;
        }
    }
}