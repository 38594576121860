.detail-section {
  .transaction-date {
    display: flex;
    justify-content: space-between;
    .date-section {
      display: flex;
      flex-direction: column;
    }
  }
  .creator-info-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 1.5rem;
    .creator-avatar {
      position: relative;
      width: 36px;
      height: 36px;
      margin-bottom: 0;
      border-radius: 100%;
      align-self: center;
    }
  }
}
