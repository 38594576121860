.filter-bar {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .filters {
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 8px 16px;
        position: absolute;
        margin-top: 45px;
        top: 0px;
        left: 0px;
        z-index: 9999;
        white-space: nowrap;
        li {
            span {
                padding-left: 8px;
            }

            input[type='checkbox'] {
                accent-color: #CC0000;
            }
        }
    }

    .left-side {
        margin-right: auto;
        display: inline-flex;
        position: relative;
    }

    .right-side {


        input {
            margin-left: 0px !important;
            height: 36px;
            border-radius: 40px;
        }
    }

    .input-date {
        width: 216px;
        border-radius: 32px;
        display: flex;
        align-items: center;
        border: 1px solid #2E69FF;
        padding: 0 10px;

        input {
            height: 36px;
            width: 100%;
            border-radius: 32px;
            text-align: center;
            border-width: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.03em;
            color: #2E69FF;
        }
    }

    .text-date {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #2E69FF;
    }

    .react-datepicker__day {
        font-weight: 400;
        font-size: 14px;
        letter-spacing: -0.03em;
        color: #111111;
        height: 32px;
        width: 32px;
        margin: 0;
        border-radius: 0px;
        padding-top: 3px;
        background-color: white;

    }

    .react-datepicker__day:hover {
        font-weight: 400;
        font-size: 14px;
        letter-spacing: -0.03em;
        color: #111111;
        height: 32px;
        width: 32px;
        margin: 0;
        border-radius: 0px;
        padding-top: 3px;
        background: #CC0000;
        color: white;
        border-radius: 9999px;

    }

    // .react-datepicker__month {
    //     background: #E49194;
    //     background-color: #E49194;
    //     opacity: 100%
    // }

    .react-datepicker__day--in-selecting-range {
        background: #E49194;
        background-color: #E49194;
        opacity: 100%
    }

    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range) {
        background: #E49194;
        background-color: #E49194;
        opacity: 100%
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker {
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
        border-width: 1px;
    }

    .react-datepicker__header {
        background-color: white;
        border-bottom: 0px;

    }

    .react-datepicker__day-name {
        color: #A3A3A3;
        text-align: center;
    }

    .react-datepicker__navigation {
        color: #111111;
    }

    .react-datepicker__navigation-icon::before {
        border-color: black !important;
    }

    .react-datepicker__day--in-range {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #CC0000;
        color: white;
    }

    .react-datepicker__day--range-end {
        background: #CC0000;
        color: white;
        border-radius: 0px 9999px 9999px 0px;
    }

    .react-datepicker__day--range-start {
        background: #CC0000;
        color: white;
        border-radius: 9999px 0px 0px 9999px;
    }

    .react-datepicker__input-container {
        position: static;
    }

    .text-amount {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #A3A3A3;
    }


    .inDatePicker{
        margin-top: 7.5; margin-left: 16; color: '#2E69FF'; font-weight: "700";white-space: nowrap;
    }

    .delete-icon {
        font-size: 22px;
        font-weight: 800;
        cursor: pointer;
    }

    .delete-country {
        position: absolute;
        bottom: 0;
        right: 10px;
    }
}

@media screen and (max-width: 576px) {
    .left-container, .right-side {
        width: 100%;
    }

    .left-side {
        width: 100%;
        justify-content: space-between;
    }
}