.pill {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    font-family: "HelveticaNowDisplay";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #111111;

    &.admin {
        background: #FAE5E5;
        border: 1px solid #CC0000;
    }

    &.contr {
        background: #D5E1FF;
        border: 1px solid #2E69FF;
    }

    &.deny {
        background: #FAE5E5;
        border: 1px solid #CC0000;
    }

    &.confirm {
        background: #EDFAF0;
        border: 1px solid #4ECC6A;
    }

    &.active {
        background: #EDFAF0;
        border: 1px solid #4ECC6A;
    }

    &.open {
        background: #D5E1FF;
        border: 1px solid #2E69FF;
    }

    &.solved {
        background: #EDFAF0;
        border: 1px solid #4ECC6A;
    }

    &.posts {
        background: #D5E1FF;
        border: 1px solid #2E69FF;
    }

    &.clips {
        background: #EDFAF0;
        border: 1px solid #4ECC6A;
    }

    &.stories {
        background: rgba(0, 0, 0, 0.25);
        border: 1px solid #111111;
    }

    &.messages {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F2994A;
        border: 1px solid #F2994A;
    }

    &.tips {
        background: rgba(0, 0, 0, 0.25);
        border: 1px solid #111111;
    }

    &.subscriptions {
        background: #FAE5E5;
        border: 1px solid #CC0000;
    }

    &.cleared {
        background: #EDFAF0;
        border: 1px solid #4ECC6A;
    }

    &.pending {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F2994A;
        border: 1px solid #F2994A;
    }
}
