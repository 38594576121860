.filter-card {
    font-family: "HelveticaNowDisplay";
    letter-spacing: -0.03em;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    flex: 1;
    padding: 16px;
    letter-spacing: -0.03em;

    .filter-card-header {
        display: flex;

        .header-text {
            margin-right: auto;

            h4 {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #111111;
            }

            div {

                font-family: "HelveticaNowDisplay";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #A3A3A3;
            }
        }

        .header-button {
            margin-left: auto;
        }
    }

    .filter-card-body {
        min-height: 100px;
    }

    .month-filter {
        border: 1px solid #EEEEEE;
        border-radius: 8px;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        width: 152px;
        height: 152px;
    }

    .month-filter-select {
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #111111;
        padding-left: 16px;
        padding-top: 14px;
    }
    .filters {
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        border-radius: 8px;

        position: absolute;
      right: -10px;
      top:40px


    }
}