$black-color: #111111;
$light-black-color: #242424;

$white-color: #ffffff;
$dark-white-color: #eeeeee;

$gray-color: #a3a3a3;
$dim-gray-color: #707070;

$lightest-gray-color: #fafafa;
$lighter-black-color: #2b2b2b;
