.header-table {
    font-family: "HelveticaNowDisplay";
    font-style: normal;
    width: 100%;

    h2 {
        
        font-weight: 800;
        font-size: 28px;
        line-height: 36px;
        color: #111111;

    }

    div {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #A3A3A3;
    }
}