.vault-page-container {
    .header {
        .add-collection {
            color: $gray-color;
        }
    }
    .image-card {
        width: 100%;
        .image-collection {
            border-radius: 8px 8px 0 0;
            width: 100%;
            border: 1px solid $dark-white-color;
            border-bottom: 0;
            overflow: hidden;
            img {
                width: 50%;
                height: auto;
            }
        }
        .collection-name {
            width: 100%;
            border-radius: 0 0 8px 8px;
            border: 1px solid $dark-white-color;
            border-top: 0;
            padding: 5px 16px;
        }
    }
}

.craete-collection-input {
    border: 1px solid $dark-white-color;
}

.create-collection-form {
    width: 60%;
    margin: auto;
}

.theme-dark {
    .vault-page-container {
        .image-card {
            .image-collection {
                border: 1px solid $light-black-color;
                border-bottom: 0;
            }
            .collection-name {
                border: 1px solid $light-black-color;
                border-top: 0;
            }
        }
    }
    .craete-collection-input {
        border: 1px solid $dim-gray-color;
    }
}

@media screen and (max-width: 576px) {
    .vault-page-container {
        margin-top: 64px;
        .image-card {
            .collection-name {
                font-size: 0.8rem;
            }
        }
    }
    .create-collection-form {
        width: 80%;
    }
}
