.user-card {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    font-family: "HelveticaNowDisplay";

    .user-header-view {
        padding: 12px;
    }

    .user-body-view {
        padding: 12px;
        border-top: solid 1px #EEEEEE;
    }
}
