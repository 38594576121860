.btn {
    border-radius: 40px;
    height: 36px;
    font-family: "HelveticaNowDisplay";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.03em;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    &.red {
        background: #CC0000;
        color: #FFFFFF;
    }

    &.green {
        background: #4ECC6A;
        color: #FFFFFF;
    }

    &.longGreen{
        background: #4ECC6A;
        color: #FFFFFF;
        width: 100%;
    }

    &.blank {
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        color: #111111;
    }

    &.filter {
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        color: #111111;
        gap: 8px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }

    &.filter-blue {
        background: #FFFFFF;
        border: 1px solid #2E69FF;
        color: #2E69FF;
        gap: 8px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        
    }
}

@media screen and (max-width: 576px) {
    .btn.filter-blue {
        width: 100%;
    }

    .filter-bar .input-date {
        width: 100% !important;
        margin-bottom: 16px;
    }
}
