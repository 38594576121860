.creator-card {
    font-family: "HelveticaNowDisplay";
    letter-spacing: -0.03em;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    flex: 1;
    padding: 16px;
    height: 192px;
    display: flex;
    flex-direction: column;

    .creator-card-header {
        display: flex;
        align-items: center;
        flex: 1;

        h4 {
            margin-right: auto;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #111111;
        }
    }

    .creator-list {
        display: flex;
        width: 100%;
        flex: 4;
        align-items: center;

        .left-list {
            flex: 1;
        }

        .center-list {
            align-items: center;
            justify-content: center;
            display: flex;
            flex: 4;
            flex-direction: column;

            .avatar {
                display: inline-flex;
                align-items: center;
                height: 52px;
            
                img {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                }
            }

            h4 {
                font-family: "HelveticaNowDisplay";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.03em;
                color: #111111;
            }

            h2 {
                font-family: "HelveticaNowDisplay";
                font-style: normal;
                font-weight: 400;
                font-size: 32px;
                line-height: 40px;
                color: #111111;
                margin-top: 8px;
            }
        }

        .right-list {
            flex: 1;
        }
        
    }
    
}