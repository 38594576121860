@import "utilities/main";
@import "components/main";
@import "pages/main";

@media (min-width: 768px) {
  .main-content {
    min-height: 100vh;
  }
}

.nobar::-webkit-scrollbar {
  display: none;
}

.scheduled-date-picker {
  display: inline-block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 50px;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.h-250px {
  height: 250px;
}

.profile-main-section {
  padding-right: 4rem;
}

.see-all-button {
  font-size: 14px;
  font-weight: 700;
  color: #cc0000;
  border: 1px solid #cc0000;
}

.all-media {
  padding: 2rem 1rem;
  padding-bottom: 1rem;
}

.profile-card {
  width: 100%;
  padding: 1rem 0;
}

.social-icon {
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: #fafafa;
  padding: 5px;
  display: flex;
}

.theme-dark .social-icon {
  border: 1px solid #242424;
  background-color: #2b2b2b;
}

.profile-figure {
  top: -65px;
  left: 6px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid rgb(255, 255, 255);
}

.social-media-section {
  justify-content: space-evenly;
  padding-left: 3rem;
  padding-top: 0.5rem;
}

.user_id {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 800;
  color: #111;
}

.uniq_id {
  font-size: 14px;
  font-weight: 400;
  color: #a3a3a3;
}

.profile-user-icon {
  top: -40px;
  left: 30px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #212529;
}

.profile-bio-section {
  justify-content: space-between;
  position: relative;
  top: 2rem;
  padding-top: 1rem;
}

.profile {
  padding-bottom: 3rem;
}

.bio-description {
  font-size: 16px;
  font-weight: 400;
  color: #111;
}

.all-post-count {
  font-size: 16px;
  font-weight: 700;
  color: #111;
}

.theme-dark {

  .bio-description,
  .all-post-count,
  .user_id {
    color: #fff;
  }
}

// .theme-dark .checkout-option label,
// span {
//   color: #fff !important;
// }
.private-section {
  background: #ffffff;
  border: 1px solid #cc0000;
  box-shadow: 0px 0px 8px rgba(204, 0, 0, 0.2);
  border-radius: 8px;
  justify-content: space-between;
  padding: 1rem;
}

.theme-dark .private-section {
  background: #111;
  border: 1px solid #242424;
  box-shadow: none;
}

.private-title {
  font-weight: 800;
  font-size: 28px;
  color: #111;
}

.private-details {
  font-weight: 400;
  font-size: 16px;
  color: #111;
}

.checkout-option {
  display: flex;
  align-items: baseline;
}

// .checkout-option label,
// span {
//   font-weight: 700;
//   color: #111;
//   font-size: 14px;
// }
.checkout-option p {
  font-weight: 400;
  font-size: 12px;
  color: #111;
}

.logo-text {
  color: black;
}

.navbar-circle {
  display: relative;
  border-radius: 100%;
  height: 32px;
  width: 32px;
  border: 1px solid #eee;
  background-color: #fafafa;
}

.fc-daygrid-day-top {
  flex-direction: row !important;
  justify-content: flex-end;
  align-items: center;
  font-size: 10px;
}

.fc-theme-standard .fc-scrollgrid {
  border: 0 !important;
}

.notification-selected {
  color: #2e69ff;
}

.notification-border {
  border-bottom: 1px solid #2e69ff !important;
}

.notification-rest {
  color: #a3a3a3;
}

.theme-dark {
  .created-post-content {
    color: white !important;
  }

  .search-input,
  .commentInput {
    background-color: transparent;
    border: 1px solid #242424 !important;
    color: #fff;
  }

  .bg-gray {
    background-color: #242424 !important;
  }

  .vaultText {
    color: #fff !important;
  }

  .modal-header-custom {
    color: #111 !important;
  }

  .settings-item {
    color: #ffffff;
  }

  .creator-card-container {
    background-color: #111;
    border: 1px solid #111;
  }

  .fc-view {
    background-color: #111;
  }

  .post-fullname {
    color: white;
  }

  .navbar-circle {
    background-color: #2b2b2b;
    border: 1px solid #242424;
  }

  .post-features,
  .post-features-text {
    color: white;
  }

  .post-input {
    background-color: #111;
    color: white;
  }

  .post-container {
    border: 1px solid #111;
  }

  .created-post-container {
    border: 1px solid #111;
  }

  .logo-text {
    color: white;
  }

  .profile-user-icon {
    border: 3px solid rgb(255, 255, 255);
  }

  .nav-content {
    background-color: #111111;
  }

  .profile-count,
  .profile-title {
    color: white !important;
  }

  .fullname-profile {
    color: white;
  }

  .username-profile,
  .notificationTime {
    color: #707070 !important;
  }

  .nav-header {
    background-color: #111111 !important;
  }

  .basic-features {
    color: #fff;
  }

  .feeds-title,
  .darkthemeUser,
  .darktheme-txt {
    color: #fff !important;
  }

  .darkthemeTime,
  .text-black {
    color: #707070;
  }

  .iconBackground {
    background-color: #2b2b2b !important;
    border: 1px solid #242424 !important;
  }

  .darkthemeButton {
    color: #111 !important;
    background-color: #ffff !important;
  }

  .modal-content {
    background: #111;
  }

  .modal-header {
    border-bottom: 1px solid #242424;
  }

  .modal-footer {
    border-top: 1px solid #242424;
  }

  .sound-wave {
    background-color: #111111;
  }

  .shedulePost {
    background-color: #2b2b2b;
    // border-radius: 25rem;
    border: 1px solid #242424;
  }

  .darkThemeExpiration {
    background-color: #2b2b2b;
    border-radius: 25rem;
    border: 1px solid #242424;
  }

  .darkthemeDay {
    background-color: #242424;
    color: #fff;
  }

  .trendingPostsection {
    background-color: #111111;
  }

  .trendingTitle,
  .name,
  .darkreactionList,
  .darkThemetext {
    color: #fff;
  }

  ._darkIcon {
    color: #fff;
  }

  .create-plan {
    border-color: #242424;
  }

  .subscribe-count {
    color: #fff;
  }

  .plan-option {
    color: #707070;
  }

  .tab-bordar {
    border-bottom: 1px solid #242424;
  }

  .icon-tab,
  .plan-list {
    background-color: transparent !important;
    color: #707070;
    border-color: #242424;
  }
}

.tab-bordar {
  border-bottom: 1px solid #eeee;
}

.darkThemeExpiration {
  border-radius: 25rem;
  border: 1px solid #a3a3a3;
}

.newVault {
  display: flex;
  align-items: baseline;
}

.creator-cover-image-figure {
  height: 100px;
}

.cover-image-div {
  position: relative;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
}

.h35 {
  height: 35px !important;
}

.h-50vh {
  height: 50vh;
}

.h-100px {
  height: 100px;
}

.h-200px {
  height: 200px;
}

.h-250px {
  height: 250px;
}

.h-400px {
  height: 400px;
}

.h-auto {
  height: auto;
}

.creator-profile-figure {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  border: 3px solid rgb(255, 255, 255);
}

.creator-profile-figure-subscription-plan {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  border: 3px solid rgb(255, 255, 255);
  margin-top: -40px;
}

.user-profile-figure {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  // border: 3px solid rgb(255, 255, 255);
}

.chat-menu-profile-figure {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.user-profile-figure-menubar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.overlap-image {
  margin-left: -15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.theme-dark .form-group label .photo-id {
  background-color: #333 !important;
  border-color: var(--theme-color) !important;
  color: #515184 !important;
  line-height: 1.5 !important;
}

.theme-dark .content-color {
  color: #fff !important;
}

.theme-dark .checklist-color ul {
  color: #fff !important;

  li {
    list-style: disc !important;
  }
}

.checklist-color ul {
  li {
    list-style: disc !important;
  }
}

// .theme-dark .text-color {
//   color: #000 !important;
// }

.queue-section {
  width: 100% !important;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
}

.grid-item {
  text-align: center !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.queue-datepicker {
  & .react-datepicker-popper {
    transform: translate(180px, 119px) !important;

    &.react-datepicker__triangle {
      transform: translate(120px, 0px) !important;
    }
  }
}

.mt-100px {
  margin-top: 100px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .p-xs-0 {
    padding: 0 !important;
  }

  .rounded-xxl {
    border-radius: 0 !important;
  }
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .theme-dark {
    .all-bookmarks-header {
      position: fixed;
      z-index: 5;
      background-color: #1a2236;
    }

    .all-bookmarks-spacing {
      padding-top: 50px;
    }
  }

  .all-bookmarks-header {
    position: fixed;
    z-index: 5;
    background-color: #fbfcfe;
  }

  .all-bookmarks-spacing {
    padding-top: 50px;
  }
}

.mouse-hover {
  cursor: pointer;
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }
}

.account-form .react-tel-input {
  width: 80%;
}

.react-tel-input {
  .form-control {
    line-height: 50px;
    height: 50px;
    font-size: 14px;
    display: inline-block;
    border: 2px #eee solid;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    padding-left: 60px;
    border-radius: 25rem;

    &:focus {
      border: 2px var(--theme-color) solid;
    }
  }

  .selected-flag {
    width: 50px;
    height: 100%;
    padding: 0 0 0 13px;
    border-radius: 0.25rem;

    &:focus {
      background-color: #ffffff;
    }

    &:hover {
      background-color: #ffffff;
    }
  }

  .flag-dropdown {
    background-color: #ffffff;
    border: 2px solid #eee;
    border-radius: 25rem;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      border: 2px var(--theme-color) solid;
    }
  }

  .flag-dropdown.open {
    background: #ffffff;
    border-radius: 0.25rem;

    .selected-flag {
      background: #ffffff;
      border-radius: 0.25rem;

      &:focus {
        border: 2px var(--theme-color) solid;
      }
    }
  }
}

.theme-dark .react-tel-input {
  .form-control {
    line-height: 50px;
    height: 50px;
    font-size: 14px;
    display: inline-block;
    border: 2px solid #242424;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    padding-left: 60px;
    border-radius: 25rem;
  }

  .selected-flag {
    width: 50px;
    height: 100%;
    padding: 0 0 0 13px;
    border-radius: 0.25rem;

    &:focus {
      background-color: transparent !important;
    }

    &:hover {
      background-color: transparent !important;
    }
  }

  .flag-dropdown {
    background-color: transparent !important;
    border: 2px solid #242424;
    border-radius: 25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .flag-dropdown .open {
    background: #1a2236 !important;
    border-radius: 0.25rem;

    .selected-flag .open {
      background: #1a2236 !important;
      border-radius: 0.25rem;
    }
  }
}

.h-400px {
  height: 400px;
}

.h-50vh {
  height: 50vh;
}

.setting-icon {
  right: 20px;
}

.moon-icon {
  right: 70px;
}

.queue-media {
  border-radius: 10px;
  font-size: 0.875rem;
  height: 50px;
  position: relative;
  width: 50px;
  z-index: 2;
}

.category-media {
  position: relative;
  z-index: 2;
  width: 50px;
  display: inline-block;

  img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }
}

.item-container {
  .react-video-thumbnail-image {
    position: relative;
    z-index: 2;
    width: 50px;
    display: inline-block;
    margin-left: -15px;

    .category-media-image {
      width: 50px;
      height: 50px;
      border-radius: 10px;
    }
  }
}

.queue-list-wrapper {
  height: calc(100vh - 350px);
}

.scheduled-label {
  border-radius: 5px;
  color: white;
}

th[role="columnheader"] {
  font-size: 12px;
  font-weight: 700;
}

.fc-theme-standard td {
  border: 0 !important;
}

.fc-theme-standard th {
  border: 0 !important;
  padding: 5px 0 !important;
}

.fc-view {
  background-color: white;
}

.fc-toolbar-title {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent !important;

  .fc-daygrid-day-number {
    color: #ffffff !important;
    background: #2e69ff;
    border-radius: 50%;
    text-align: center;
    width: 28px;
    height: 28px;
  }
}

.fc-daygrid-event-dot {
  display: none;
}

.fc-event-time {
  display: none;
}

.fc-daygrid-dot-event .fc-event-title {
  text-align: center;
  background-color: #cc0000;
  color: white;
}

.fc {
  .fc-daygrid-day-number {
    color: gray !important;
  }

  .fc-col-header-cell-cushion {
    color: gray !important;
  }
}

.voice-record {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin-left: auto;

  i {
    color: rgba(1, 75, 128, 1);
    font-size: 50px;
    margin-left: 10px;
    border-radius: 50%;
    border: none;
    background-color: rgba(255, 255, 255, 1);
  }
}

.voice-record {
  .active {
    i {
      color: red;
    }
  }
}

.queue-box li a {
  padding-left: unset !important;
}

.loader {
  z-index: 999999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader2 {
  z-index: 999999;
  position: absolute;
  top: 0%;
  left: 0%;
  // transform: translate(-50%, -50%);
}

.modal-loader {
  position: relative;
  height: 25vh;
  z-index: 9999;
  background-color: transparent;
  width: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.middle-sidebar-bottom {
  .loader {
    position: fixed;
    background: none;
  }

  .modal-loader {
    position: fixed;
    background: none;
  }
}

.main-overlay {
  position: fixed;
  /* Sit on top of the page content */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  /* Black background with opacity */
  z-index: 100;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
  opacity: 0.4;
}

.theme-dark .main-overlay {
  background-color: white;
}

.cross-icon {
  right: 2px;
  top: 2px;
  z-index: 1;
}

.post-image-video {
  height: auto;
  width: 100%;
}

.chat-image-video {
  height: 100px;
  width: 100px;
  border-radius: 0.3rem;
  object-fit: cover;
}

.z-index-15 {
  z-index: 15;
}

.z-index-99 {
  z-index: 99;
}

.feather-icon-color {
  color: #adb5bd !important;
}

.theme-dark.feather-icon-color {
  color: white !important;
}

.disable {
  pointer-events: none;
}

.comment-section {
  line-height: 1.5;
  text-align: left;
}

.comment-input {
  line-height: 50px;
  height: 50px;
  font-size: 14px;
  display: inline-block;
  border: 2px #eee solid;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  color: #515184;
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.comment-area {
  .position-absolute__control {
    border-radius: 10px;

    textarea {
      line-height: 1.9;
    }
  }
}

.comment-area {
  .position-relative__control {
    border-radius: 10px;

    .position-relative__highlighter {
      min-height: 100px !important;
    }

    textarea {
      min-height: 100px !important;
      line-height: 1.7;
    }
  }
}

.hide-post-scroll {
  &::-webkit-scrollbar {
    width: 0.5px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
  }
}

.theme-dark {
  .hide-post-scroll {
    &::-webkit-scrollbar {
      width: 0.5px;
      background-color: #1a2236;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #1a2236;
    }
  }
}

.hide-scroll {
  &::-webkit-scrollbar {
    width: 5px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
  }
}

.edit-post-text {
  color: #a3a3a3 !important;
  margin-left: 0.5rem;
}

.theme-dark {
  .edit-post-text {
    color: #707070 !important;
  }
}

.input-placeholder-text {
  color: black !important;
}

.theme-dark {
  .input-placeholder-text {
    color: white !important;
  }
}

.bookmark-color {
  color: #feca28;
}

.star-icon {
  // top: 2px;
  z-index: 1;
  left: 40px;
  bottom: -6px;
}

.comment-user-profile-figure {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.comment-emoji-wrap {
  position: absolute;
  // bottom: 70px;
  width: 80%;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  width: auto;
  background-color: #fff;
  padding: 5px 10px 5px 25px;
  border-radius: 30px;
  display: none;
}

.comment-emoji-wrap.active {
  display: block;
}

.post-image-container {
  height: 100px !important;
  width: 150px !important;
  object-fit: cover !important;

  video {
    height: 100px !important;
    max-width: 150px !important;
    object-fit: cover !important;
  }

  .react-player__preview {
    height: 100px !important;
    max-width: 150px !important;
    border-radius: 0.3rem !important;
  }
}

.chat-image-container {
  height: 200px !important;
  width: 200px !important;
  object-fit: cover !important;

  video {
    height: 200px !important;
    max-width: 200px !important;
    object-fit: cover !important;
    border-radius: 0.6rem !important;
  }

  .react-player__preview {
    height: 200px !important;
    max-width: 200px !important;
    border-radius: 0.6rem !important;
  }
}

.show-post-image {
  height: 215px !important;
  width: 230px !important;
  object-fit: cover !important;

  video {
    height: 215px !important;
    width: 230px !important;
    object-fit: cover !important;
  }

  .react-player__preview {
    height: 215px !important;
    width: 230px !important;
    border-radius: 0.3rem !important;
  }
}

.modal-post-image {
  height: 150px !important;
  width: 175px !important;
  object-fit: cover !important;

  video {
    height: 150px !important;
    width: 160px !important;
    object-fit: cover !important;
  }

  .react-player__preview {
    height: 150px !important;
    width: 175px !important;
    border-radius: 0.3rem !important;
  }
}

.activeCard {
  font-weight: 700;
  color: var(--theme-color) !important;
  border-bottom: 1px solid var(--theme-color) !important;
}

.bookmark-image {
  height: 215px !important;
  width: 200px !important;
  object-fit: cover !important;
}

.bookmark-video {
  video {
    height: 215px !important;
    width: 200px !important;
    object-fit: cover !important;
  }

  .react-player__preview {
    height: 215px !important;
    width: 210px !important;
    border-radius: 0.3rem !important;
  }
}

.react-loading-skeleton {
  --base-color: #eee !important;
  --highlight-color: #adb5bd !important;
}

.theme-dark {
  .react-loading-skeleton {
    --base-color: #eee !important;
    --highlight-color: #adb5bd !important;
  }
}

.chat-skeleton-color {
  .react-loading-skeleton {
    --base-color: #eee !important;
    --highlight-color: #adb5bd !important;
  }
}

.theme-dark {
  .chat-skeleton-color {
    .react-loading-skeleton {
      --base-color: #1a2236 !important;
      --highlight-color: #293145 !important;
    }
  }
}

.chat-body-skeleton-color {
  .react-loading-skeleton {
    --base-color: #eee !important;
    --highlight-color: #adb5bd !important;
  }
}

.theme-dark {
  .chat-body-skeleton-color {
    .react-loading-skeleton {
      --base-color: #293145 !important;
      --highlight-color: #1a2236 !important;
    }
  }
}

.video-overlay {
  position: absolute;
  height: calc(100vh - 20px);
  max-width: 1000px;
  background: #fff !important;
  z-index: 9999;
}

.theme-dark {
  .video-overlay {
    position: absolute;
    height: calc(100vh - 20px);
    max-width: 1000px;
    background: #1a2236 !important;
    z-index: 9999;
  }
}

.blur-box {
  filter: blur(6px);
  user-select: none;
  pointer-events: none;
  height: 300px;
}

.unlock-text {
  width: 80%;
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: #262d3482;
  border-radius: 15px;
}

.theme-dark {
  .unlock-text {
    width: 80%;
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #e8f4ff4f;
    border-radius: 15px;
  }
}

.h30 {
  height: 30px !important;
}

.pt-50px {
  padding-top: 50px;
}

.pt-70px {
  padding-top: 70px;
}

.pt-100px {
  padding-top: 100px;
}

.pt-120px {
  padding-top: 120px;
}

.pt-150px {
  padding-top: 150px;
}

.left-30 {
  left: 30px;
}

.bookmark-dropdown-icon {
  padding-left: 0;
  padding-right: 25px;
}

.bookmark-dropdown-list {
  right: 10% !important;
  top: 85px !important;
}

.chat-dropdown-list {
  right: 5% !important;
  top: 13% !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.bookmark-list-icon {
  position: absolute;
  z-index: 15;
  right: 0% !important;
  top: 49px !important;
}

.bg-green {
  background-color: #4cd964;
}

.bg-cyan-gradiant {
  background: linear-gradient(to right, #00a9a8, #086e6e) !important;
}

.bg-darkpink-gradiant {
  background: linear-gradient(to right, #e2446c, #721d32) !important;
}

.bg-blue-green-gradiant {
  background: linear-gradient(to left, #8fff85, #39a0ff);
}

.bg-gray {
  background-color: #eeeeee;
}

.text-gray {
  color: #858585 !important;
}

.text-green {
  color: #4ecc6a !important;
}

.vault-modal-checkbox-image-div {
  top: 0% !important;
  right: 4% !important;
  z-index: 5;

  .form-check-input:checked {
    background-color: #cc0000 !important;
    border-color: #cc0000 !important;
  }

  .form-check-input:focus {
    border-color: #000000 !important;
  }
}

.checkbox-image-div {
  top: 0% !important;
  right: 4% !important;
  z-index: 5;

  .form-check-input:checked {
    background-color: #cc0000 !important;
    border-color: #cc0000 !important;
  }

  .form-check-input:focus {
    border-color: #000000 !important;
  }
}

.checkbox-image {
  background-color: rgba(17, 17, 17, 0.5) !important;
  border: 1px solid #cc0000;
  height: 22px !important;
  width: 22px !important;
}

.vault-heading {
  background: #fbfcfe;
  z-index: 9;
  height: 100px;
}

.theme-dark {
  .vault-heading {
    background: #1a2236;
    z-index: 9;
    height: 100px;
  }

  .notificationCard {
    background-color: #111;
    border: 1px solid #242424;
  }

  .notification-rest {
    color: #707070;
  }

  .count {
    background-color: #242424;
  }
}

.mt-30px {
  margin-top: 30px;
}

.mt-50px {
  margin-top: 50px;
}

.mt-100px {
  margin-top: 100px;
}

.mt-110px {
  margin-top: 110px;
}

.add-new-category {
  color: #1683ff;
}

.blurModal {
  opacity: 0.5;
}

.vertical-center {
  vertical-align: middle !important;
}

.btn-small {
  width: 30px !important;
  height: 30px !important;
  border-radius: 45px;
  display: inline-block;
  line-height: 30px !important;
  text-align: center;
}

.mediaCategory-dropdown-list {
  position: absolute;
  right: 45% !important;
}

.category-list-div::-webkit-scrollbar {
  display: none;
}

.category-list-div {
  width: 100% !important;
  overflow-y: scroll;
}

.notification-profile-image {
  width: 50px !important;
  height: 50px !important;
  object-fit: cover !important;
  border-radius: 50% !important;
}

.notification-count {
  top: 0;
  left: 24px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding-left: 3px !important;
  padding-right: 3px !important;
  line-height: 19px !important;
  color: #1e74fd !important;
  background-color: #d2e3ff !important;
  border-color: #bcd5fe !important;
}

.message-notification-count {
  top: 6px;
  left: 22px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  line-height: 19px !important;
  background-color: #000000 !important;
}

.modal-text-black {
  color: #000 !important;
}

.media-button-chat {
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.chat-image-box {
  width: calc(100% - 90px);
  color: #ddd;
  border-radius: 10px;
  float: left;
  margin: 0 5px 0 0;
  position: relative;
}

.bg-muted {
  background-color: #aeaed5 !important;
}

.chat-send-button {
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.chat-media-box {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.chat-top {
  position: absolute;
  z-index: 11;
  width: 98%;
  background-color: #fff;
}

.no-chat-message {
  height: calc(100vh - 305px) !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-dropdown-menu {
  // position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #515184;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.top-unset {
  top: unset !important;
}

.right-4 {
  right: 25px !important;
}

.right-5 {
  right: 15px !important;
}

@media screen and (max-width: 512px) {
  .rec-carousel-item {
    // height: calc(100dvh - 64px) !important;
    height: 100dvh !important; //test da vedere



    // margin-top: 32px !important; se si mette si scassano le storie quindi da non suare 
    // assolutamente e se serve inserirlo solo nelle clip tramite una classe padre
  }

  .fc-today-button {
    margin-bottom: 5px !important;
    margin-left: 0.75em !important;
  }
}

.theme-dark {
  .dropdown-menu {
    background-color: #111 !important;
    border: 1px solid #242424;
  }

  .modal-text-black {
    color: #000 !important;
  }
}

.infinite-scroll-component__outerdiv {
  height: 100% !important;
}

.w-270 {
  width: 270px;
}

.pricing-card {
  transition: transform 0.5s;
}

.pricing-card:hover {
  transform: scale(1.05);
  transition: transform 0.5s;
}

.price-header {
  background-image: linear-gradient(to right,
      #71c5ee,
      #53a7d8,
      #3889c1,
      #1e6caa,
      #025091);
}

.price-button,
.price-button:hover,
.price-button:focus {
  background-color: #99d5f8;
  color: #025091;
  border: none;
}

.clear-search-button,
.clear-search-button:hover,
.clear-search-button:focus {
  background-color: #57525277;
  color: #000000;
  border: none;
}

.bg-payment-white {
  background-color: #fff !important;
}

.payment-checkout-header {
  background-image: linear-gradient(to right,
      #71c5ee,
      #53a7d8,
      #3889c1,
      #1e6caa,
      #025091);
  border-radius: 5px 5px 0 0;
}

.form-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.w-95 {
  width: 95%;
}

.w-90 {
  width: 90%;
}

.w-15 {
  width: 15%;
}

.w-25 {
  width: 25%;
}

.w-40 {
  width: 40%;
}

.lh-25 {
  line-height: 25px !important;
}

.bg-blue-gradient {
  background-image: linear-gradient(to right,
      #71c5ee,
      #53a7d8,
      #3889c1,
      #1e6caa,
      #025091);
}

.bg-black-gradient {
  background-image: linear-gradient(to right,
      #999999,
      #777777,
      #555555,
      #333333,
      #111111);
}

.bg-light-black-gradient {
  background-image: linear-gradient(to right, #111111, #333333, #555555);
}

.border-top-right {
  border-radius: 5px 5px 0 0;
}

.border-bottom-left {
  border-radius: 0 0 5px 5px;
}

.border-bottom-active {
  border-bottom: 1px solid #2e69ff !important;
  border-radius: 2px;
}

// .active-tab {
//     background-color: rgba(243, 238, 238, 0.973);
// }

.theme-dark {
  .border-bottom-active {
    border-bottom: 1px solid #2e69ff !important;
    border-radius: 2px;
  }

  // .active-tab {
  //     background-color: rgba(243, 238, 238, 0.226);
  // }
}

.edit-subscription-plan-button,
.edit-subscription-plan-button:hover,
.edit-subscription-plan-button:focus {
  background-color: #99d5f8;
  color: #025091;
  border: none;
}

.delete-subscription-plan-button,
.delete-subscription-plan-button:hover,
.delete-subscription-plan-button:focus {
  background-color: #f5aa9c;
  color: #ce2014;
  border: none;
}

.delete-subscription-plan-button-disabled,
.delete-subscription-plan-button-disabled:hover,
.delete-subscription-plan-button-disabled:focus {
  background-color: #f5aa9c;
  color: #ce2014;
  border: none;
  opacity: 0.7;
}

.p-10px {
  padding: 10px !important;
}

.p-6px {
  padding: 6px !important;
}

.p-50px {
  padding: 50px !important;
}

.height-265px {
  min-height: 265px !important;
}

.input-search-icon {
  margin-left: -38px !important;
  margin-right: 12.58px !important;
  margin-top: 11px !important;
  z-index: 2 !important;
  display: none !important;
}

.active-status-button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  display: inline-block;
  line-height: 10px !important;
  text-align: center;
  top: 36px;
  left: 38px;
}

.text-user-name {
  color: #858585;
}

.bottom-3 {
  bottom: 3px;
}

.bottom-40px {
  bottom: 40px;
}

.top-50px {
  top: 50px;
}

.top-100px {
  top: 100px;
}

.left-10px {
  left: 10px;
}

.object-fit-cover {
  object-fit: cover !important;
}

.bg-dark-green {
  background-color: #228b22;
}

.bg-light-red {
  background-color: #fabcbc;
}

.bg-red {
  background-color: #d40000 !important;
}

.border-black {
  border: #000;
}

.unsubscribe-button,
.subscriptionSubscibe,
.unsubscribe-button:hover,
.subscriptionSubscibe:active,
.subscriptionSubscibe:focus {
  background-color: #d40000;
  border-color: #d40000 !important;
}

.creatorButtonSubscribe {
  background-color: transparent;
  color: #d40000;
  font-size: 12px !important;
  font-weight: 700;
  border: 1px solid #d40000;
  width: 80% !important;
  margin-bottom: 1.5rem;
}

.creatorButton {
  background-color: #d40000;
  border: 1px solid #d40000;
  width: 80% !important;
  margin-bottom: 1.5rem;
  font-size: 12px !important;
}

.button-sction {
  text-align: center;
}

.creatorHeader {
  display: flex;
  justify-content: space-between;
}

.subscriptionFilter {
  background-color: transparent;
  color: #a3a3a3;
  border: 1px solid #a3a3a3;
  border-radius: 25rem;
}

.creatorModal .btn-primary:hover {
  background-color: transparent;
  color: #a3a3a3;
  border: 1px solid #a3a3a3;
}

.creatorModal .btn-primary:active {
  background-color: transparent;
  color: #a3a3a3;
}

.reSubscribe {
  background-color: #a3a3a3;
  border-color: #a3a3a3;
}

.text-green {
  color: #228b22;
}

.text-light-red {
  color: #d40000 !important;
}

.text-444444 {
  color: #444444;
}

.text-555555 {
  color: #555555;
}

.text-c7c9cc {
  color: #c7c9cc;
}

.theme-btn {
  color: #555555 !important;
}

.theme-dark {
  .theme-btn {
    color: #ffffff !important;
  }
}

.opacity-07 {
  opacity: 0.7;
}

.text-dark-gray {
  color: #777777;
}

// .nav-content-btn {
//   background-image: linear-gradient(#000000, #000000);
//   background-size: 0 100%;
//   background-repeat: no-repeat;
//   transition: 0.4s;
// }

.nav-content-btn {
  color: #a3a3a3 !important;
}

.nav-content-btn:hover {
  color: #cc0000 !important;
}

.nav-tab-active {
  color: #cc0000 !important;
  // border-radius: 45px;
  display: inline-block;
  line-height: 45px !important;
  text-align: center;
  border-left: 4px solid #cc0000;
}

.nav-tab-active span {
  color: #cc0000 !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #000 !important;
}

.css-u9osun.Mui-focused {
  color: #000 !important;
}

.circle-icon {
  color: #d40000;
  border-radius: 50%;
  padding: 10px;
  border: 3px solid #d40000;
  width: 50px;
  height: 50px;
}

.chat-user-image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.chat-user-search {
  border: none;
}

input[type="radio"]#Black {
  accent-color: #cc0000;
}

.bookmarkModal input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.subscriptionModal input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.checkout-option input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.checkout-option input[type="radio"]:checked {
  background-color: #cc0000;
  border: 1px solid #cc0000;
}

.chat-user-search::placeholder {
  color: #b3b3b3;
  font-weight: 600;
}

.border-none {
  border: none;
}

.new-message-count {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #000000;
}

.pb-30px {
  padding-bottom: 30px;
}

.mb-30px {
  margin-bottom: 35px;
}

.clear-message-button:hover {
  background-color: #ecb0b0;
  color: #d40000 !important;
  border-radius: 50%;
  text-align: center;
  padding: 5px;
}

// input[type="radio"]#Black {
//   accent-color: rgb(0, 0, 0);
// }
.bookmarkModal input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.subscriptionModal input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.checkout-option input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.checkout-option input[type="radio"]:checked {
  background-color: #cc0000;
  border: 1px solid #cc0000;
}

.bookmarkModal input[type="radio"]:checked {
  background-color: #cc0000;
  border: 1px solid #cc0000;
}

.subscriptionModal input[type="radio"]:checked {
  background-color: #cc0000;
  border: 1px solid #cc0000;
}

.creatorModal input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: transparent;
  border-radius: 50%;
}

.creatorModal input[type="radio"]:checked {
  background-color: #cc0000;
  border: 1px solid #cc0000;
}

// New custom CSS
.bookmark-post {
  padding: 24px 32px;
  margin-bottom: 2rem;
}

.empty-state-chat {
  background-color: #1111111a;
  font-size: 14px;
}

.bookmark-menu {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  padding-top: 24px;
  padding-left: 32px;
  width: 90%;
  margin: 0 auto;
}

.search-input {
  background-color: #fafafa;
}

.find-creator-avatar {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  margin: 0 auto;
  padding-top: 40px;
  position: relative;
  top: 2rem;
}

.creatorprofile {
  margin-top: 4rem;
}

.creatorId {
  font-size: 12;
  font-weight: 400;
  color: #a3a3a3;
}

.profileDetails {
  text-align: center;
}

.detailTitle {
  font-size: 10px;
}

.profile-name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.profile-count {
  font-weight: 700;
  font-size: 12px;
}

.profile-title {
  font-weight: 400;
  font-size: 10px;
}

.nav-content {
  background-color: white;
  margin-top: -10px;
}

.navbar-container {
  margin-top: 24px;
  padding-bottom: 40px;
}

.navbar-container ul {
  display: flex;
  gap: 8px;
}

.navbar-item {
  display: flex;
  align-items: center;
  height: 36px;
  padding-left: 0px;
}

.feeds-title {
  font-weight: 800;
  font-size: 20px;
  color: #111111;
  margin-bottom: 16px;
}

.post-container {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 24px 0px;
  margin-bottom: 18px;
}

.modal-header-custom {
  color: #111;
  font-size: 12px;
  font-weight: 700;
}

.post-input {
  border: 0;
  width: 100%;
  height: 50px;
}

.advanced-title {
  font-weight: 700;
  font-size: 10px;
  color: #a3a3a3;
}

.advanced-features {
  display: flex;
  align-items: center;
  // background-color: #ecb0b0;
  color: #d40000 !important;
  border-radius: 50%;
  text-align: center;
}

.vault-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.creator-card-container {
  background-color: white;
  border: 1px solid #eee;
  margin-top: 16px;
  border-radius: 8px;
}

.settings-item {
  font-size: 14px;
  color: #111111;
}

.setting-item-active {
  font-weight: 700;
  border-left: 3px solid #111;
}

.theme-dark .setting-item-active {
  border-left: 3px solid #fff;
}

.setting-item-span {
  padding-left: 2rem;
}

.active-tab-color {
  color: #2e69ff;
}

// end of custom CSS

.chat-menu-item:hover {
  span {
    color: #20a7db !important;
  }

  color: #20a7db !important;
  background-color: #cfecf7;
  border-radius: 5px;
}

.post-avatar {
  background-color: red;
  height: 32px;
  width: 32px;
  margin-right: 12px;
  border-radius: 100%;
}

.post-dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #a3a3a3;
  cursor: pointer;
}

.post-dropdown-item:hover {
  color: #cc0000;
}

.post-features {
  color: #111111;
}

.post-features-text {
  font-size: 12px;
  font-weight: 400;
  margin-left: 4px;
}

.calendarView .fc .fc-scroller-harness {
  border-bottom: 1px solid #eee;
}

.calendarView .fc-view {
  border: 1px solid #eee;
  border-radius: 8px;
}

.notificationCard {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  margin-top: 1rem;
}

.notifiCationHeading {
  font-size: 20px;
}

.tabSection {
  padding: 1rem 1rem 0 1rem;
}

.count {
  padding: 2px 6px;
  background: #eee;
  border-radius: 4px;
  margin-left: 5px;
  padding-bottom: 1px;
}

.tabHr {
  margin-top: 0px;
  width: 90%;
}

.tabTitle {
  display: inline-block;
  padding-right: 2rem;
}

.notificationTime {
  color: #a3a3a3;
}

.theme-dark .notificationDropdown {
  width: 100%;
  background-color: #111;
  border-radius: 36px;
  font-size: 14px;
  font-weight: 700;
  color: #2e69ff;
  padding: 0.7rem 1rem;
  margin: 1rem 0;
  border: 1px solid #111;
}

.notificationDropdown {
  width: 100%;
  background-color: #fff;
  border-radius: 36px;
  font-size: 14px;
  font-weight: 700;
  color: #2e69ff;
  padding: 0.7rem 1rem;
  margin: 1rem 0;
  border: 1px solid #eee;
}

.filterButton {
  padding: 1rem 0;
  text-align: right;
  width: 90%;
}

.filterButton .btn-primary {
  background-color: transparent;
  color: #a3a3a3;
  border-radius: 25rem;
  border: 1px solid #a3a3a3;
  padding: 8px 14px;
}

.sortSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 0 auto;
}

.sortButton {
  width: auto;
}

.sortButton .btn-primary {
  background-color: transparent;
  color: #a3a3a3;
  border-radius: 25rem;
  border: 1px solid #c4c4c4;
}

.theme-dark .filterButton .btn-primary {
  border: 1px solid #111;
}

.theme-dark .bookmarkText {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}

.plan-details {
  font-size: 14px;
  font-weight: 700;
  color: #111;
}

.theme-dark .plan-details {
  color: #fff;
}

.mainlabel {
  font-size: 10px;
  font-weight: 700;
  color: #a3a3a3;
}

.theme-dark .bookmarkModal {
  width: 14%;
  padding: 1rem !important;
  position: absolute;
  background: #111;
  z-index: 1;
  right: 31% !important;
  top: 17rem !important;
  border: 1px solid #242424 !important;
}

.bookmarkModal {
  width: 14%;
  padding: 1rem !important;
  position: absolute;
  background: #ffff;
  z-index: 1;
  right: 31% !important;
  top: 17rem !important;
  border: 1px solid #eee !important;
}

.subscriptionModal {
  width: 14%;
  padding: 1rem !important;
  position: absolute;
  background: #ffff;
  z-index: 1;
  left: 24% !important;
  top: 16rem !important;
  border: 1px solid #eee !important;
}

.theme-dark .subscriptionModal {
  width: 14%;
  padding: 1rem !important;
  position: absolute;
  background: #111;
  z-index: 1;
  left: 24% !important;
  top: 16rem !important;
  border: 1px solid #242424 !important;
}

.bookmarkText {
  font-size: 12px;
  font-weight: 400;
  color: #111;
}

.bookmarkTitle {
  font-size: 20px;
  font-weight: 800;
  padding: 1rem 4rem;
  color: #111;
}

.theme-dark .bookmarkTitle {
  color: #fff;
}

.theme-dark .filterButton .btn-primary {
  border: 1px solid #707070;
}

.subscriptionHeading {
  font-size: 20px;
  font-weight: 800;
  color: #111;
}

.subscriptionCard {
  background-color: #fff;
  height: auto;
  border-radius: 8px;
  padding-bottom: 4rem;
}

.theme-dark .subscriptionCard {
  background-color: #111;
}

.theme-dark .subscriptionHeading {
  color: #fff;
}

.subscription-menu {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  padding-top: 24px;
  width: 98%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 700;
}

.searchIconSubscription {
  top: 12px !important;
}

.theme-dark .userCard {
  background-color: #2b2b2b;
}

.userCard {
  background-color: #fafafa;
  padding: 1rem;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
}

.cardDetails {
  font-size: 12px;
  font-weight: 400;
  color: #a3a3a3;
}

.alretSection {
  display: flex;
  align-items: baseline;
  padding: 0.5rem;
  margin: 1rem;
  background-color: #fae5e5;
  border-radius: 8px;
}

.message {
  margin: 0 10px;
  font-size: 12px;
  font-weight: 400;
  color: #111;
}

.middleSection {
  padding-left: 4rem;
}

.mediaNotfound {
  text-align: center;
}

.notFoundtitle {
  font-size: 20px;
  font-weight: 800;
  color: #111;
  padding-top: 1rem;
}

.notFoundmessage {
  font-size: 14px;
  font-weight: 400;
  width: 60%;
  margin: 0 auto;
  padding: 0.5rem 0;
}

.Subscription-text {
  color: #a3a3a3;
}

.theme-dark .Subscription-text {
  color: #707070 !important;
}

.theme-dark {

  .notFoundmessage,
  .text-darkblack,
  .price-text,
  .message,
  .detailTitle {
    color: #fff;
  }

  .features-container {
    flex-direction: column;
  }

  .dark-heading {
    color: #fff;
  }

  .custome-check {
    background: #242424;
    border: 1px solid #242424;
    border-radius: 24px;
    background-image: url("https://res.cloudinary.com/dun2j5pyv/image/upload/v1675420234/dark_pteqk0.png");
    background-repeat: no-repeat;
    background-position: left center;
  }
}

.price-text {
  color: #000;
}

.responsiveDropdown {
  display: none;
}

.modalButton {
  width: auto !important;
}

.creatorModal {
  width: 20%;
  padding: 1rem !important;
  position: absolute;
  background: #ffff;
  z-index: 1;
  top: 8rem !important;
  border: 1px solid #eee !important;
  right: 22% !important;
}

.theme-dark .creatorModal {
  background-color: #111;
  border-color: #242424 !important;
}

.theme-dark .subscriptionFilter {
  border-color: #707070;
}

.archive-menu {
  font-size: 14px;
  font-weight: 400;
  color: #a3a3a3;
}

.main-archived-title {
  font-size: 20px;
  font-weight: 800;
  color: #111;
}

.theme-dark .main-archived-title {
  color: #fff;
}

.archived-section {
  padding-left: 3rem;
}

.filter-input-label {
  font-weight: 700;
  font-size: 14px;
  color: #111111;
  padding: 4px 8px 4px 12px;
  background-color: #a3a3a3;
  border-radius: 20px 0 0 20px;
}

.filter-input {
  border: 1px solid #eeeeee;
  padding: 3px 5px;
  border-radius: 0 20px 20px 0;
  width: 80%;
  font-size: 14px;
}

.theme-dark .filter-input {
  background-color: #111111;
  border: 1px solid #242424;
}

.creator-section {
  width: 114%;
}

.private-button {
  background-color: #d40000;
  border: 1px solid #d40000;
  width: 100%;
  margin-bottom: 1.5rem;
  font-size: 12px !important;
}

.responsive-img {
  width: 100%;
  height: auto;
}

.account-form {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 16px;
}

.account-inputtype {
  width: 80%;
  margin-left: 0rem;
  border-radius: 25rem;
}

.description-inputtype {
  border-radius: 12px;
  width: 80%;
  background-color: transparent !important;
}

.lable-font {
  font-size: 12px;
  font-weight: 700;
  color: #111;
  text-align: right;
}

.form-submit {
  font-size: 14px;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)),
    #cc0000;
  border-radius: 40px;
  padding: 0.7rem 1.5rem;
}

.theme-dark .form-submit {
  background: #290000;
  color: #a3a3a3 !important;
}

.delete-account {
  font-size: 14px;
  color: #cc0000;
}

.form-submission {
  padding: 1rem 2rem;
}

.profile-image {
  padding: 0 5rem 0.5rem;
}

.account-sidebar {
  padding: 2rem;
  height: 80vh;
  position: relative;
}

.log-out {
  position: absolute;
  bottom: 0;
  padding-left: 2rem;
}

.sidebar-option {
  border-right: 1px solid #eeeeee;
}

.theme-dark .sidebar-option {
  background-color: #111 !important;
  border-color: #242424;
}

.create-plan {
  background-color: transparent;
  border: 1px solid #eeee;
  border-radius: 8px;
  padding: 0;
}

.plan-card {
  background: linear-gradient(267.77deg, #cc0000 0%, #990000 100%);
  border-radius: 8px;
  padding: 1.5rem;
}

.archive-plan {
  background: linear-gradient(261.14deg, #111111 0%, #3c3c3c 100%);
}

.subscribe-count {
  font-size: 40px;
  font-weight: 400;
  color: #111;
}

.plan-amount {
  font-size: 40px;
  font-weight: 800;
}

.plan-option {
  display: flex;
  align-items: center;
}

.plan-list {
  border-radius: 25rem;
  padding: 0.5rem !important;
  border: 1px solid #eeeeee;
}

.session-history {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  margin: 15px 32px;
}

._two-step-auth {
  height: 100vh;
}

// End of new custom CSS

.basic-features {
  display: flex;
  align-items: center;
}

.advanced-title {
  font-weight: 700;
  font-size: 10px;
  color: #a3a3a3;
}

.advanced-features {
  display: flex;
  align-items: center;
  // background-color: #ecb0b0;
  color: #d40000 !important;
  border-radius: 50%;
  text-align: center;
}

.vault-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.creator-card-container {
  background-color: white;
  border: 1px solid #eee;
  margin-top: 16px;
  border-radius: 8px;
}

.settings-item {
  font-size: 14px;
  color: #111111;
}

.active-tab-color {
  color: #2e69ff;
}

// end of custom CSS

.chat-menu-item:hover {
  span {
    color: #20a7db !important;
  }

  color: #20a7db !important;
  background-color: #cfecf7;
  border-radius: 5px;
}

.chat-menu-item-danger:hover {

  span,
  i {
    color: #db2020 !important;
  }

  background-color: #f7d0cf;
  color: #db2020 !important;
  border-radius: 5px;
}

.h-290px {
  height: 290px;
}

.message-search {
  border: 1px solid gray;
}

.chat-message-dropdown-item:hover {
  color: #9e9a9a !important;
}

.created-post-container {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 24px 0px;
  margin-bottom: 18px;
}

.created-post-avatar-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.created-post-avatar {
  position: relative;
  width: 36px;
  height: 36px;
  margin-bottom: 0;
  border-radius: 100%;
  align-self: center;
}

.created-post-content {
  margin-top: 16px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  width: 100% !important;
  color: #111111 !important;
  padding-left: 1rem;
}

.created-post-content>span {
  width: 100% !important;
}

.created-post-features {
  justify-content: space-between;
  margin-top: 16px;
}

.customeValidation {
  display: flex;
  align-items: center;
}

.scheduleInputtype {
  border-radius: 25rem;
  border: 1px solid #a3a3a3;
}

.scheduleLable {
  font-size: 12px;
  display: flex;
  width: 50%;
  margin: 0 auto;
  font-weight: 700;
}

.fc .fc-button {
  background-color: transparent !important;
  color: #111 !important;
  opacity: 1 !important;
  border: 1px solid #111 !important;
  border-radius: 25rem !important;
  padding: 3px 15px !important;
}

.calendarView .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child),
.fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
  margin-right: 1rem;
  border-radius: 100%;
  width: 2rem !important;
  height: 2rem !important;
  padding: 2px !important;
}

.calendarView .fc .fc-scroller-harness {
  border-bottom: 1px solid #eee;
}

.calendarView .fc-view {
  border: 1px solid #eee;
  border-radius: 8px;
}

.notificationCard {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  margin-top: 1rem;
}

.notifiCationHeading {
  font-size: 20px;
}

.tabSection {
  padding: 1rem 1rem 0 1rem;
}

.count {
  padding: 2px 6px;
  background: #eee;
  border-radius: 4px;
  margin-left: 5px;
  padding-bottom: 1px;
}

.tabHr {
  margin-top: 0px;
  width: 90%;
}

.tabTitle {
  display: inline-block;
  padding-right: 2rem;
}

.notificationTime {
  color: #a3a3a3;
}

.theme-dark .notificationDropdown {
  width: 100%;
  background-color: #111;
  border-radius: 36px;
  font-size: 14px;
  font-weight: 700;
  color: #2e69ff;
  padding: 0.7rem 1rem;
  margin: 1rem 0;
  border: 1px solid #111;
}

.notificationDropdown {
  width: 100%;
  background-color: #fff;
  border-radius: 36px;
  font-size: 14px;
  font-weight: 700;
  color: #2e69ff;
  padding: 0.7rem 1rem;
  margin: 1rem 0;
  border: 1px solid #eee;
}

.filterButton {
  padding: 1rem 0;
  text-align: right;
  width: 90%;
}

.filterButton .btn-primary {
  background-color: transparent;
  color: #a3a3a3;
  border-radius: 25rem;
  border: 1px solid #a3a3a3;
  padding: 8px 14px;
}

.sortSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 0 auto;
}

.sortButton {
  width: auto;
}

.sortButton .btn-primary {
  background-color: transparent;
  color: #a3a3a3;
  border-radius: 25rem;
  border: 1px solid #c4c4c4;
}

.theme-dark .filterButton .btn-primary {
  border: 1px solid #111;
}

.theme-dark .bookmarkText {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}

.plan-details {
  font-size: 14px;
  font-weight: 700;
  color: #111;
}

.theme-dark .plan-details {
  color: #fff;
}

.mainlabel {
  font-size: 10px;
  font-weight: 700;
  color: #a3a3a3;
}

.theme-dark .bookmarkModal {
  width: 14%;
  padding: 1rem !important;
  position: absolute;
  background: #111;
  z-index: 1;
  right: 31% !important;
  top: 17rem !important;
  border: 1px solid #242424 !important;
}

.bookmarkModal {
  width: 14%;
  padding: 1rem !important;
  position: absolute;
  background: #ffff;
  z-index: 1;
  right: 31% !important;
  top: 17rem !important;
  border: 1px solid #eee !important;
}

.subscriptionModal {
  width: 14%;
  padding: 1rem !important;
  position: absolute;
  background: #ffff;
  z-index: 1;
  left: 24% !important;
  top: 16rem !important;
  border: 1px solid #eee !important;
}

.theme-dark .subscriptionModal {
  width: 14%;
  padding: 1rem !important;
  position: absolute;
  background: #111;
  z-index: 1;
  left: 24% !important;
  top: 16rem !important;
  border: 1px solid #242424 !important;
}

.bookmarkText {
  font-size: 12px;
  font-weight: 400;
  color: #111;
}

.bookmarkTitle {
  font-size: 20px;
  font-weight: 800;
  padding: 1rem 4rem;
  color: #111;
}

.theme-dark .bookmarkTitle {
  color: #fff;
}

.theme-dark .filterButton .btn-primary {
  border: 1px solid #707070;
}

.subscriptionHeading {
  font-size: 20px;
  font-weight: 800;
  color: #111;
}

.subscriptionCard {
  background-color: #fff;
  height: auto;
  border-radius: 8px;
  padding-bottom: 4rem;
}

.theme-dark .subscriptionCard {
  background-color: #111;
}

.theme-dark .subscriptionHeading {
  color: #fff;
}

.subscription-menu {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  padding-top: 24px;
  width: 98%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 700;
}

.searchIconSubscription {
  top: 12px !important;
}

.theme-dark .userCard {
  background-color: #2b2b2b;
}

.userCard {
  background-color: #fafafa;
  padding: 1rem;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
}

.cardDetails {
  font-size: 12px;
  font-weight: 400;
  color: #a3a3a3;
}

.alretSection {
  display: flex;
  align-items: baseline;
  padding: 0.5rem;
  margin: 1rem;
  background-color: #fae5e5;
  border-radius: 8px;
}

.message {
  margin: 0 10px;
  font-size: 12px;
  font-weight: 400;
  color: #111;
}

.middleSection {
  padding-left: 4rem;
}

.mediaNotfound {
  text-align: center;
}

.notFoundtitle {
  font-size: 20px;
  font-weight: 800;
  color: #111;
  padding-top: 1rem;
}

.notFoundmessage {
  font-size: 14px;
  font-weight: 400;
  width: 60%;
  margin: 0 auto;
  padding: 0.5rem 0;
}

.Subscription-text {
  color: #a3a3a3;
}

.theme-dark .Subscription-text {
  color: #707070;
}

.theme-dark {

  .notFoundmessage,
  .text-darkblack,
  .price-text,
  .message,
  .detailTitle {
    color: #fff;
  }

  .alretSection {
    background-color: #290000;
  }
}

.price-text {
  color: #000;
}

.responsiveDropdown {
  display: none;
}

.modalButton {
  width: auto !important;
}

.creatorModal {
  width: 20%;
  padding: 1rem !important;
  position: absolute;
  background: #ffff;
  z-index: 1;
  top: 8rem !important;
  border: 1px solid #eee !important;
  right: 22% !important;
}

.theme-dark .creatorModal {
  background-color: #111;
  border-color: #242424 !important;
}

.theme-dark .subscriptionFilter {
  border-color: #707070;
}

.archive-menu {
  font-size: 14px;
  font-weight: 400;
  color: #a3a3a3;
}

.main-archived-title {
  font-size: 20px;
  font-weight: 800;
  color: #111;
}

.theme-dark .main-archived-title {
  color: #fff;
}

.archived-section {
  padding-left: 3rem;
}

.filter-input-label {
  font-weight: 700;
  font-size: 14px;
  color: #111111;
  padding: 4px 8px 4px 12px;
  background-color: #a3a3a3;
  border-radius: 20px 0 0 20px;
}

.filter-input {
  border: 1px solid #eeeeee;
  padding: 3px 5px;
  border-radius: 0 20px 20px 0;
  width: 80%;
  font-size: 14px;
}

.theme-dark .filter-input {
  background-color: #111111;
  border: 1px solid #242424;
}

.creator-section {
  width: 114%;
}

.private-button {
  background-color: #d40000;
  border: 1px solid #d40000;
  width: 100%;
  margin-bottom: 1.5rem;
  font-size: 12px !important;
}

.responsive-img {
  width: 100%;
  height: auto;
}

.account-form {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 16px;
}

.account-inputtype {
  width: 80%;
  margin-left: 0rem;
  border-radius: 25rem;
}

.description-inputtype {
  border-radius: 12px;
  width: 80%;
  background-color: transparent !important;
}

.lable-font {
  font-size: 12px;
  font-weight: 700;
  color: #111;
  text-align: right;
}

.form-submit {
  font-size: 14px;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)),
    #cc0000;
  border-radius: 40px;
  padding: 0.7rem 1.5rem;
}

.delete-account {
  font-size: 14px;
  color: #cc0000;
}

.form-submission {
  padding: 1rem 2rem;
}

.profile-image {
  padding: 0 5rem 0.5rem;
}

.account-sidebar {
  padding: 2rem;
  height: 80vh;
  position: relative;
}

.log-out {
  position: absolute;
  bottom: 0;
}

.sidebar-option {
  border-right: 1px solid #eeeeee;
}

.create-plan {
  background-color: transparent;
  border: 1px solid #eeee;
  border-radius: 8px;
  padding: 0;
}

.plan-card {
  background: linear-gradient(267.77deg, #cc0000 0%, #990000 100%);
  border-radius: 8px;
  padding: 1.5rem;
}

.archive-plan {
  background: linear-gradient(261.14deg, #111111 0%, #3c3c3c 100%);
}

.subscribe-count {
  font-size: 40px;
  font-weight: 400;
  color: #111;
}

.plan-amount {
  font-size: 40px;
  font-weight: 800;
}

.plan-option {
  display: flex;
  align-items: center;
}

.plan-list {
  border-radius: 25rem;
  padding: 0.5rem !important;
  border: 1px solid #eeeeee;
}

.session-history {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  margin: 15px 32px;
}

.basic-features {
  display: flex;
  align-items: center;
}

.features-container {
  flex-direction: column;
}

// End of new custom CSS

@media screen and (min-width: 280px) and (max-width: 390px) {
  .checkbox-image-div {
    top: 0% !important;
    right: 20% !important;
    z-index: 5;

    .form-check-input:checked {
      background-color: #1e74fd !important;
      border-color: rgb(119, 123, 128) !important;
    }

    .form-check-input:focus {
      border-color: rgb(119, 123, 128) !important;
    }
  }

  .fc .fc-daygrid-day.fc-day-today {
    .fc-daygrid-day-number {
      width: 30px;
      height: 30px;
    }
  }
}

@media screen and (min-width: 390px) and (max-width: 512px) {
  .checkbox-image-div {
    top: 0% !important;
    right: 22% !important;
    z-index: 5;

    .form-check-input:checked {
      background-color: #1e74fd !important;
      border-color: rgb(119, 123, 128) !important;
    }

    .form-check-input:focus {
      border-color: rgb(119, 123, 128) !important;
    }
  }

  .fc .fc-daygrid-day.fc-day-today {
    .fc-daygrid-day-number {
      width: 30px;
      height: 30px;
    }
  }
}

@media screen and (min-width: 540px) and (max-width: 576px) {
  .checkbox-image-div {
    top: 0% !important;
    right: 30% !important;
    z-index: 5;

    .form-check-input:checked {
      background-color: #1e74fd !important;
      border-color: rgb(119, 123, 128) !important;
    }

    .form-check-input:focus {
      border-color: rgb(119, 123, 128) !important;
    }
  }
}

@media screen and (max-width: 400px) {
  .modal-post-image {
    height: 110px !important;
    width: 175px !important;
    object-fit: cover !important;

    video {
      height: 110px !important;
      width: 100px !important;
      object-fit: cover !important;
    }

    .react-player__preview {
      height: 110px !important;
      width: 175px !important;
      border-radius: 0.3rem !important;
    }
  }
}

@media screen and (max-width: 512px) {
  .fc-today-button {
    margin-bottom: 5px !important;
    margin-left: 0.75em !important;
  }

  .notification-profile-image {
    width: 35px !important;
    height: 35px !important;
    object-fit: cover !important;
    border-radius: 50% !important;
  }

  .notification-count {
    top: -6px;
    left: 20px;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    padding-left: 3px !important;
    padding-right: 3px !important;
    line-height: 18px !important;
    color: #1e74fd !important;
    background-color: #d2e3ff !important;
    border-color: #bcd5fe !important;
    text-align: center;
  }

  .message-notification-count {
    top: 6px;
    left: 22px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    line-height: 19px !important;
    background-color: #000000 !important;
  }
}

@media (max-width: 576px) {
  .post-image-container {
    height: 150px !important;
    width: 230px !important;
    object-fit: cover !important;

    video {
      height: 150px !important;
      max-width: 162px !important;
      object-fit: cover !important;
    }

    .react-player__preview {
      height: 150px !important;
      max-width: 162px !important;
      border-radius: 0.3rem !important;
    }
  }
}

@media only screen and (max-width: 992px) {
  .mt-100px {
    margin-top: 0;
  }

  .bookmark-dropdown-list {
    right: 1% !important;
    top: 30px !important;
  }

  .mediaCategory-dropdown-list {
    position: absolute;
    right: 0 !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .bookmark-dropdown-list {
    right: 10% !important;
    top: 85px !important;
  }

  .mediaCategory-dropdown-list {
    position: absolute;
    right: 60% !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  // .bookmark-dropdown-list {
  //   right: 20% !important;
  //   top: 85px !important;
  // }

  .mediaCategory-dropdown-list {
    position: absolute;
    right: 10% !important;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1920px) {
  .bookmark-dropdown-list {
    right: 35% !important;
    top: 85px !important;
  }
}

@media screen and (min-width: 1920px) {
  .bookmark-dropdown-list {
    right: 35% !important;
    top: 85px !important;
  }
}

@media screen and (min-width: 1800px) {
  .bookmark-sidebar-sticky {
    position: fixed !important;
    width: 11% !important;
  }
}

@media only screen and (min-width: 992px) {
  .bookmark-sidebar-sticky {
    position: fixed !important;
    width: 18% !important;
  }
}

@media only screen and (min-width: 1530px) and (max-width: 1774px) {
  .bookmark-sidebar-sticky {
    position: fixed !important;
    width: 15% !important;
  }
}

@media only screen and (min-width: 1775px) {
  .bookmark-sidebar-sticky {
    position: fixed !important;
    width: 13% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .mt-xl-100px {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 25%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .icon-end {
    text-align: end;
  }
}

@media only screen and (min-width: 1600px) {
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }
}

.notification {
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .navbar-container ul {
    flex-direction: column;
  }

  .schedule-post-button {
    width: 100%;
  }

  .post-features {
    flex-direction: column;
  }

  .basic-features {
    justify-content: space-between;
  }

  .responsive-post {
    flex-direction: row;
  }

  .post-avatar {
    margin-top: 1rem !important;
  }

  .profile-count {
    font-size: 16px;
    font-weight: 700;
    color: #111;
  }

  .profile-title {
    font-size: 14px;
    font-weight: 400;
  }

  .voice-record {
    width: 100%;
    justify-content: space-around;
  }

  .newVault {
    display: block;
  }

  .theme-dark .nav-content {
    margin-top: 0px;
  }

  .scheduleInputtype {
    width: 80%;
  }

  .scheduleLable {
    width: 80%;
  }

  .editCardbody .card-body {
    padding: 0 !important;
  }

  .tabSection {
    display: none;
  }

  .notificationCard {
    margin: 1rem 0 0 0;
  }

  .notifiCationHeading {
    font-size: 18px;
  }

  .bookmark-post {
    padding: 24px 0;
  }

  .bookmark-menu {
    display: none;
  }

  .responsibeBookmarkCard {
    margin-top: 0rem;
    background-color: transparent;
  }

  .theme-dark .responsibeBookmarkCard {
    margin-top: 0rem;
    background-color: transparent !important;
  }

  .bookmarkTitle {
    margin-top: 4rem;
    padding: 1rem 2rem;
  }

  .responsiveHr {
    display: none;
  }

  .filterButton,
  .btn-primary {
    width: 100%;
  }

  .theme-dark .bookmarkModal {
    padding: 0rem !important;
    z-index: 2 !important;
    top: 17rem !important;
    right: 8% !important;
    width: 85% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  }

  .bookmarkModal {
    padding: 0rem !important;
    z-index: 2 !important;
    top: 17rem !important;
    right: 8% !important;
    width: 85% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  }

  .subscriptionModal {
    padding: 0rem !important;
    z-index: 2 !important;
    top: 14rem !important;
    left: 6% !important;
    width: 85% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  }

  .theme-dark .subscriptionModal {
    padding: 0rem !important;
    z-index: 2 !important;
    top: 14rem !important;
    left: 6% !important;
    width: 85% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  }

  .theme-dark .bookmarkTitle {
    color: #fff;
  }

  .userCard {
    flex-direction: column;
    text-align: center;
    padding: 1rem 0.5rem;
  }

  .userData {
    display: flex;
    flex-direction: column;
    padding: 0 0 1rem 0;
  }

  .responsiveclass {
    margin: 0 auto;
  }

  .cardDetails {
    padding: 0 0 1rem 0;
  }

  .subscription-menu {
    display: none;
  }

  .middleSection {
    padding: 0;
    margin: 5px 10px;
  }

  .theme-dark .subscriptionCard {
    background-color: #2b2b2b;
    padding-top: 4rem;
  }

  .subscriptionCard {
    padding-top: 4rem;
  }

  .theme-dark .userCard {
    background-color: #111;
  }

  .responsiveForm {
    float: none !important;
    justify-content: center;
  }

  .responsiveDropdown {
    display: block;
    // padding-top: 3rem;
  }

  .subscriptionSearch {
    width: 100% !important;
  }

  .responsive-prfile-section {
    flex-direction: column;
  }

  .profileDetails {
    display: flex;
    align-items: baseline;
    padding-left: 1rem;
  }

  .responsive-text {
    font-size: 14px !important;
    padding-right: 10px;
  }

  .detailTitle {
    font-size: 12px;
  }

  .creatorButton,
  .creatorButtonSubscribe {
    width: 90% !important;
  }

  .main-section {
    margin: 4rem 1rem 0;
  }

  .creatorHeader {
    flex-direction: column;
  }

  .main-title {
    padding: 1rem 0;
  }

  .creator-card-container {
    margin-top: 26px;
  }

  .creatorModal {
    padding: 0rem !important;
    z-index: 2 !important;
    top: 12rem !important;
    right: 5% !important;
    width: 90% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  }

  .theme-dark .subscriptionFilter {
    border-color: #242424;
  }

  .archived-section {
    padding-left: 0;
  }

  .creator-section {
    width: auto;
  }

  .fc-daygrid-day-events {
    display: none;
  }

  .fc-scrollgrid-sync-table {
    height: 200px;
  }

  .fc-daygrid-day-top {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fc-daygrid-day-top a {
    color: #111111 !important;
  }

  .theme-dark .fc-daygrid-day-top a {
    color: #fff !important;
  }

  .responsive-text {
    font-size: 14px !important;
    padding-right: 10px;
  }

  .detailTitle {
    font-size: 12px;
  }

  .creatorButton,
  .creatorButtonSubscribe {
    width: 90% !important;
  }

  .main-section {
    margin: 4rem 1rem 0;
  }

  .creatorHeader {
    flex-direction: column;
  }

  .main-title {
    padding: 1rem 0;
  }

  .creator-card-container {
    margin-top: 26px;
  }

  .creatorModal {
    padding: 0rem !important;
    z-index: 2 !important;
    top: 12rem !important;
    right: 5% !important;
    width: 90% !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  }

  .theme-dark .subscriptionFilter {
    border-color: #242424;
  }

  .archived-section {
    padding-left: 0;
  }

  .creator-section {
    width: auto;
  }

  .profile-main-section {
    padding: 6rem 2rem 0 2rem;
  }

  .profile-card {
    padding: 0 !important;
  }

  .profile-figure {
    left: 8rem;
    width: 120px !important;
    height: 120px;
  }

  .user_id {
    flex-direction: column;
  }

  .bio-description {
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
  }

  .responsive-rating {
    justify-content: left !important;
  }

  .responsive-bio {
    justify-content: space-around !important;
    padding-top: 0.5rem;
  }

  .responsive-alignment {
    text-align: center;
  }

  .social-media-section {
    justify-content: space-between;
    padding-left: 0;
  }

  .responsive-media {
    padding: 1rem !important;
    padding-left: 0 !important;
  }

  .sub-section {
    justify-content: center;
  }

  .customStyle {
    font-weight: 700;
    color: #000;
  }

  .responsive-title {
    margin: 6rem 2rem 0;
    font-size: 18px;
    font-weight: 800;
    color: #111;
  }

  .responsive-subscription {
    flex-direction: column;
    padding: 0 1rem !important;
    // padding-right: 1rem !important;
    margin: 0 !important;
  }

  ._card-mobile {
    padding: 0 2rem;
  }

  .plan-option {
    padding-top: 1.5rem;
  }

  .create-plan {
    margin: 0;
  }

  .middel_section {
    padding: 0 2rem;
  }

  .account-form {
    flex-direction: column;
    gap: 0;
  }

  .account-inputtype {
    width: 100%;
  }

  .account-form .react-tel-input,
  .description-inputtype {
    width: 100%;
  }

  .form-submission,
  .language-card {
    padding: 1rem 0 !important;
  }

  .form-submit {
    padding: 0.7rem 8rem;
  }

  .custom-button {
    text-align: center !important;
    margin-top: 1rem;
  }

  .profile-image {
    padding: 0 1rem 0.5rem;
  }

  .responsive-width {
    width: auto !important;
  }

  .sidebar-option {
    background-color: transparent !important;
  }

  .responsive-modal {
    padding: 1rem 0;
  }

  .form-control {
    height: 45px;
  }

  .session-details {
    flex-direction: column;
    align-items: baseline !important;
  }

  .session-status {
    margin-left: 0 !important;
    margin-top: 0.5rem;
  }

  .ms-me {
    margin: 0 auto;
  }

  .mt-mb {
    margin: 1rem 0;
  }

  .session-history {
    margin: 15px 8px;
  }

  .responsive-hide {
    display: none;
  }

  .responsive-section {
    display: flex;
    padding-left: 1rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: #111;
  }

  .theme-dark .responsive-sidebar {
    background-color: transparent !important;
  }

  .theme-dark .customStyle {
    color: #fff;
  }

  ._two-step-auth {
    height: auto;
  }
}

.MuiRating-icon.MuiRating-iconEmpty,
.MuiRating-icon.MuiRating-iconFilled {
  color: #f2994a;
}

.side-bar-calendar .fc-daygrid-day-events {
  display: none;
}

.side-bar-calendar .fc-scrollgrid-sync-table {
  height: 200px;
}

.side-bar-calendar .fc-daygrid-day-top {
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-bar-calendar .fc-daygrid-day-top a {
  color: #111111 !important;
}

.theme-dark .side-bar-calendar .fc-daygrid-day-top a {
  color: #fff !important;
}

div.schedule-input-type.border-0.d-sm-flex>div.me-sm-3>div>div>div,
div.schedule-input-type.border-0.d-sm-flex>div>div>div {
  border-radius: 50rem;
}

.theme-dark div.schedule-input-type.border-0.d-sm-flex>div.me-sm-3>div>div>div {
  color: #fff;
}

.theme-dark div.schedule-input-type.border-0.d-sm-flex>div>div>div {
  color: #fff;
  border: 1px solid #333333;
}

.MuiRating-icon.MuiRating-iconEmpty,
.MuiRating-icon.MuiRating-iconFilled {
  color: #f2994a;
}

.side-bar-calendar .fc-daygrid-day-events {
  display: none;
}

.side-bar-calendar .fc-scrollgrid-sync-table {
  height: 200px;
}

.theme-dark .schedule-input-type .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #fff;
}

.schedule-modal .modal-dialog {
  width: fit-content;
  max-width: none;
}

.side-bar-schedule {
  position: relative;
  background-color: #fff;
  top: -27px;
}

.theme-dark .side-bar-schedule {
  background-color: #111111;
}

.side-bar-calendar .fc-prev-button.fc-button.fc-button-primary {
  margin-right: 1rem;
  border-radius: 100%;
  width: 2rem !important;
  height: 2rem !important;
  padding: 2px !important;
}

.theme-dark .side-bar-calendar .fc-button.fc-button-primary {
  background-color: #2b2b2b !important;
  color: #fff !important;
  margin-right: 1rem;
  border-radius: 100%;
  width: 2rem !important;
  height: 2rem !important;
  padding: 2px !important;
}

.side-bar-calendar .fc-button.fc-button-primary {
  background-color: #fafafa !important;
  color: #111111 !important;
  border: 1px solid #eeeeee !important;
  margin-right: 1rem;
  border-radius: 100%;
  width: 2rem !important;
  height: 2rem !important;
  padding: 2px !important;
}

.fc-scroller.fc-scroller-liquid-absolute,
.fc-scroller {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

.fc-scroller.fc-scroller-liquid-absolute::-webkit-scrollbar,
.fc-scroller::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
  align-items: baseline;
  padding-left: 1rem;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .main-content.right-chat-active {
    overflow-x: hidden;
    margin-top: 4rem;
  }

  .navbar-container ul {
    flex-direction: column;
  }

  .middleSection {
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .creator-section {
    width: 100vw;
    padding-left: 2rem;

    .creatorHeader {
      padding-top: 1rem;
    }
  }

  .profile-main-section {
    padding-right: 0 !important;

    .sub-section {
      div {
        .user-info-card {
          margin: 0 3rem auto 2rem;
          width: unset !important;
        }
      }
    }
  }

  .archived-section {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .main-content {
    .middle-sidebar-bottom {
      padding: 1rem 1rem !important;

      .middle-sidebar-left {
        margin: 0 1rem !important;
      }
    }
  }

  .bookmarkTitle {
    padding: 1rem 2rem 0;
  }

  .userCard {
    width: 95% !important;
    margin: 0.5rem auto !important;
    max-width: 95%;
  }

  .find-creator-dropdown {
    top: 8rem !important;
  }

  .navbar-search-icon {
    top: 11px !important;
  }

  .filter-button-archieve {
    padding-right: 2rem;
    width: 100%;
  }

  .archive-filter {
    margin-left: 1rem;
  }

  .subscription-filter-button {
    margin-left: 1rem;
  }

  .sortButton {
    width: 50% !important;
    flex: none;
  }

  .subscription-search-button {
    width: 50% !important;
    flex: none;
  }

  .subscriptionSubscibe {
    letter-spacing: unset;
    font-size: 12px !important;
  }

  .feather-bell.tab {
    color: #a3a3a3 !important;
    border: 1px solid #eeeeee !important;
    background-color: #fafafa !important;
    zoom: 70%;
  }

  .theme-dark .feather-bell.tab {
    background-color: #2b2b2b !important;
    border: 1px solid #242424 !important;
  }
}

.no-data {
  font-size: 1.3em;
  color: #A3A3A3;
  text-align: center;
}

.admin-form {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .form-label {
    font-size: 14px;
    font-weight: 700;
    color: #111111;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  .form-input {
    border-radius: 40px;
    height: 36px;
    border: 1px solid #EEEEEE;
    padding: 0 15px;
    width: 100%;
  }
}

.profile-img {
  object-fit: cover;
}

.text-only-btn {
  font-size: 12px;
  color: #2E69FF;
  display: flex;
  justify-content: center;
  margin: 24px auto 0;
  border: none;
  background-color: transparent;
}

.submit-box {
  margin-left: 48px;
  margin-right: 128px;
  width: 200px;
  max-height: 200px;
  min-width: 200px;
  min-height: 150px;
  background-color: #FCFBFC;
}

@media screen and (max-width: 576px) {
  .support-detail-cols {
    flex-wrap: wrap-reverse;
    row-gap: 20px;

    .submit-box {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      min-height: 100px;
    }
  }

  .modal-card {
    width: 90vw;
  }

  .btn.filter {
    width: 100%;
  }

  .filter-bar .left-side {
    flex-wrap: wrap;
    row-gap: 16px;
    margin-bottom: 0 !important;

    .extra-fields {
      width: 100%;

      .btn.red {
        width: 100%;
      }
    }

  }

  .filter-bar {
    row-gap: 16px;
  }

  ul.filters {
    width: 100%;
  }

  .amount-filter-btn {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
  }

  .filters-element {
    width: 100%;
  }

  .filter-list :first-child .amount-filter-btn {
    margin-top: 16px;
  }
}

.activity-dot {
  height: 8px;
  width: 8px;
  background-color: #CC0000;
  border-radius: 50%;
  display: inline-flex;
  margin-left: 2px;
  position: relative;
  bottom: 10px;
}


.divider {
  width: 100%;
  border-bottom: 1px solid var(--border-day, #EEE);
}

input[type=checkbox] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 2px solid #eee;
  border-radius: 4px;
  cursor: pointer;
  height: 16px;
  outline: none;
  position: relative;
  width: 16px;
}

input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

input[type=checkbox]:checked {
  background-color: #c80000;
  border-color: #c80000;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #515184;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23515184' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.select-admin-creation {
  border: 1px solid #eee;
  border-radius: 40px;
  font-size: 14px !important;
  height: 36px !important;
  line-height: 20px;
  max-height: -moz-fit-content;
  max-height: -webkit-fit-content;
  max-height: fit-content;
  padding: 8px 36px 8px 16px !important;
  width: 100%;
}

.select-admin-creation:focus {
  border: 1px solid #eee !important;
}

.flex {
  display: flex;
}

.search-fee-creator {
  width: 100%;
  padding-right: 32px;
  border-radius: 248px;
  border: 1px solid #EEE;
  padding-left: 16px;
}

.dropdown-select-fee-creator {
  display: grid;
  position: absolute;
  top: 40px;
  background-color: black;
  width: 100%;
  padding: 8px 16px;
  gap: 12px;
  border-radius: 8px;
  z-index: 2;
  border: 1px solid var(--border-day, #EEE);
  background: var(--background-day, #FFF);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
}

.box-dropdown-fee-creator {
  height: 24px;
  gap: 8px;
  align-items: center;
  color: var(--text-day, #111);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.42px;
}

.box-select-creator-fee {
  width: calc(100% - 120px);
  display: flex;
  flex-wrap: wrap;
  padding: 6px 16px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #EEE;
  background: #FFF;
  gap: 8px;
  margin-top: 16px;
}

.box-creator-fee {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
  border: 1px solid var(--border-day, #EEE);
}

.name-fee-creator {
  color: var(--text-day, #111);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.36px;
}

.hover {
  cursor: pointer;
}

.label-fee-add {
  color: var(--text-day, #111);
  text-align: right;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.42px;
  width: 96px;
  text-align: right;
  margin-right: 24px;
  align-items: center;
  display: grid;
}

.input-date-fee {
  width: calc(50% - 70px);

  input {
    width: 100%;
    border-radius: 248px !important;
    border: 1px solid #EEE !important;
    display: flex !important;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between !important;
    align-items: flex-start !important;
    flex: 1 0 0 !important;

  }
}

.icon-fee-tab {
  width: 20px;
  height: 20px;
  background-color: #CC0000;
  color: #FFFFFF;
  border-radius: 16px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
}

.edit-fee-card {}

.title-edit-fee {
  color: var(--text-day, #111);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.54px;
}

.advise-edit-fee {
  color: var(--gray-day, #A3A3A3);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.48px;
  margin-top: -8px;
  margin-bottom: -8px;
}

.description-edit-fee {
  color: var(--gray-day, #A3A3A3);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.42px;
}

.info-fee-user {
  color: var(--text-day, #111);
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
}

.label-fee-edit {
  color: var(--text-day, #111);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.42px;
  margin-right: 24px;
  align-items: center;
  display: flex;
}

.logo-red-circle-login {
  height: 150px;
}

@media(max-width:512px) {
  .fix-ul{
    ul{
      width: auto;
    }
  }
  .fix-modal-dialog{
    .modal-dialog{
      width: 100% !important;
    }
  }
  .logo-red-circle-login {
    height: 110px;
    text-align: center
  }

  .rec-carousel {
    overflow: hidden;
  }

  .logo-red-circle {
    height: 40px;
  }
}

@media(min-width:513px) {
  .rec-carousel {
    min-width: 100%;
  }

  .logo-red-circle {
    height: 50px;
  }
}


.hideDots {
  .control-dots {
    display: none;
  }
}

.carousel-status {
  display: none;
}

.carousel {
  .control-dots {
    margin-top: 100px !important;
  }
}

.fix-carousel-chat {
  img {
    max-height: 332px !important;
  }
}

.width-image-common-post {
  width: auto !important;
  max-width: 100%;
}

.medals {
  position: absolute;
  top: 0px;
  left: -4px;
  height: 27px !important;
  width: 17.5px !important;
  z-index: 1;
}
.fix-tick{
  display: flex;
  align-items: center;
  img{
    height: 16px !important;
    width: 16px !important;
  }
}
.prevent-swipedown {
  overscroll-behavior-y: contain;
}
.loader-image {
  display: block;
  width: 100% !important;
  height: 100%;
  place-items: center;
  text-align: center;

  img {
      width: 100px !important;
      height: 100px !important;
      animation: spinner 1s linear infinite;
      max-width: 100px !important;
      max-height: 100px !important;
  }
}


@-webkit-keyframes spinner {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
.fix-modal-set{
  display: flex !important;
  align-items: center !important;
}

.fix-date-picker-profile{
.react-datepicker-wrapper{
  width: 100%;
}}
