.red-pill {
    font-family: "HelveticaNowDisplay";
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.03em;
    background: #CC0000;
    color: #FFFFFF;
    padding: 4px 12px;
    width: 66px;
    height: 28px;
    border-radius: 36px;
}

li {
    span {
        padding-left: 8px;

        font-family: "HelveticaNowDisplay";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #111111;
    }

    input[type='checkbox'] {
        accent-color: #CC0000;
    }
}

.inline-button {
    padding-left: 70px;
}

.nodisplay {
    display: none;
}

.attachment-text {
    cursor: pointer;
    color: blue;
}

.right {
    text-align: -webkit-right;
    justify-content: right;
}

.left {
    text-align: -webkit-left;
    justify-content: left;
}

.reply-text {
    color: #A3A3A3;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.42px;
    margin-top: 24px;
}

.flex {
    display: flex;
}

h2 {

    font-weight: 800;
    font-size: 28px;
    line-height: 36px;
    color: #111111;

}

.maxW {
    width: 100% !important;
}

.header-user-card {
    font-family: "HelveticaNowDisplay";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #111111;

    .user-card {
        margin-top: 8px;
    }
}

.modal-collab {
    width: 640px;
    max-width: 90vw;
    max-height: 90%;
    overflow-y: scroll;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    gap: 0px !important;
}

@media (max-width: 503px) {
    .modal-collab {
        width: 90%;
        max-height: 90%;
    }

}






.flex-detail {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .element-card {
        margin-bottom: 16px;
        width: 100%;

        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.03em;
            color: #A3A3A3;
        }

        h2 {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.03em;
            color: #111111;
        }

        img {
            width: 100%;
        }

        .link-view {
            ul {

                li,
                a {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.03em;
                    color: #111111;
                    margin-bottom: 5px;
                    display: inline-flex;
                    gap: 5px;
                    width: 100%;
                }
            }
        }

    }
}

.center {
    text-align: -webkit-center;
}

.input-collab-container {
    margin-top: 8px;
    margin-bottom: 8px;
    display: contents;
}

.footer-flex-detail {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.15);
    margin-left: -31px;
    padding: 16px 32px;
    background: #FFFFFF;

    >span {
        margin-right: auto;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 28px;
        color: #111111;
    }

    .footer-right-buttons {
        margin-left: auto;
        gap: 16px;
        display: flex;
    }
}

.rounded {
    border-radius: 9999px !important;
}

.fit {
    object-fit: cover;
}

.input-collab {
    width: 100%;
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: black;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.width50 {
    width: 50%;
}

.add-cob {
    position: relative;
    top: -52px;
}

@media (max-width: 501px) {
    .add-cob {
        position: relative;
        top: 0px;
        width: 100%;
        text-align: right;
    }
}

.width100 {
    width: 100%;
}

.width150px {
    width: 150px;
}

.center2 {
    justify-content: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.mr40 {
    margin-right: 40px;
}

.image-card {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    font-family: "HelveticaNowDisplay";

    .image-view-card {
        padding: 12px;

        >img {
            width: 100%;
        }

        >span {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #111111;
        }
    }

    .detail-view-card {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #EEEEEE;
        padding: 12px;

        >h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.03em;
            color: #A3A3A3;
        }

        >h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.03em;
            color: #111111;
        }

        >span {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.03em;
            color: #111111;

        }
    }

}

.card-margin {
    margin-top: 30px;
}

.flex-2 {
    display: flex;
    gap: 16px;

    .flex-data {
        flex: 1;
        flex-direction: column;
        display: flex;

        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.03em;
            color: #A3A3A3;
        }

        h2 {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.03em;
            color: #111111;
        }
    }
}

.flex-4 {
    display: flex;

    .flex-data {
        flex: 1;
        flex-direction: column;
        gap: 0px;
        display: flex;

        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.03em;
            color: #A3A3A3;
        }

        div {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.03em;
            color: #111111;
        }
    }
}

.inline-fee {
    display: inline-flex;
    width: 100%;
    height: 36px;
    align-items: center;

    label {
        width: 70px;
        font-family: "HelveticaNowDisplay";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #111111;
    }

    input {
        margin-left: 0px !important;
        height: 100%;
        border-radius: 40px;
    }

}

.container-card {
    min-height: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;
    background: #FFFFFF;
    margin-top: 24px;
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    font-family: "HelveticaNowDisplay";
    letter-spacing: -0.03em;

    .title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #111111;
    }

    .subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #111111;
    }
}

.camp-collab {
    align-self: center;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: left;
    padding-left: 8px;
    letter-spacing: -0.03em;

    /* text[day] */

    color: #111111;
    margin-right: 16px;
}

.width300px {
    width: 300px
}

.width100px {
    width: 100px
}

@media (max-width: 501px) {
    .width300px {
        width: 100%
    }
}

// .checkbox-collab{

// }
.checkbox-collab-container {
    margin-bottom: 16px;
}

.noscroll {
    overflow: hidden;
}

.container-table {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 16px;
    background: #FFFFFF;
    margin-top: 24px;
    border: 1px solid #EEEEEE;
    border-radius: 8px;

    .table-container {
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
    }


    table {
        font-family: "HelveticaNowDisplay";
        margin-bottom: auto;
        letter-spacing: -0.03em;

        tr {
            width: 100%;
            height: 52px;

            th {
                color: #A3A3A3;

                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                border-bottom: 1px solid #EEEEEE;
            }

            td {
                color: #111111;

                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;

                &.center {
                    text-align: center;
                }

                &.avatar {
                    display: inline-flex;
                    align-items: center;
                    height: 52px;

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                }

                div.subdata {
                    color: #A3A3A3;
                    font-size: 14px;
                }

                div.linked {
                    color: #2E69FF;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .action {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 52px;

            .subaction {
                justify-content: center;
                width: 100%;
                align-items: center;
                display: inline-flex;
                gap: 10px;
            }
        }
    }

    .paginator {
        margin-top: 20px;
    }
}

.red-flag {
    display: inline-flex;
    align-items: center;
    padding: 6px;
    width: 24px;
    height: 24px;
    background: #FAE5E5;
    color: #CC0000;
    border-radius: 4px;
}

.green-flag {
    display: inline-flex;
    align-items: center;
    padding: 6px;
    width: 24px;
    height: 24px;
    background: #EDFAF0;
    color: #4ECC6A;
    border-radius: 4px;
}

.main-content {
    background: #FFFFFF;
    padding-top: 48px;
    padding-left: 312px;
    padding-right: 48px;
}

@media (max-width: 992px) {
    .main-content {
        background: #FFFFFF;
        padding-top: 80px;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .fix-profile-phone {
        margin-top: 20px !important;
    }

}

@media (min-width: 993px) {
    .correct-card-profile {

        transform: none !important;
        position: relative !important;
        border: 1px solid #EEEEEE;
        top: 0;
        right: 0;

    }

}

.nobar {
    display: flex;
    width: 100%;
    min-height: 600px;
    flex-direction: column;

    .scrollable {
        margin-bottom: auto;
        overflow-x: auto;

        table {
            width: 100%;
            min-width: 600px;
        }
    }

    .paginator {
        margin-top: 10px;
    }
}

.hide-scroll-bar::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
}

td {
    max-width: 300px;
    min-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 20px;
}

.tab-picture {
    max-width: 36px;
    max-height: 36px;
}

@media (min-width: 993px) {
    .tab-action-fixed {
        position: sticky;
        right: 0;
        background-color: white;
        z-index: 1;
        height: 52px;
    }
}

.pr-8 {
    padding-right: 8px;
}

.header-logo {
    justify-content: "center";
    display: inline-flex;
    align-items: center;

    i {
        padding-right: 7px;
    }

    .logo-text {
        padding-right: 12px;
    }
}

.th160 {
    width: 160px;
}

.th180 {
    width: 180px;
}

.bg-navigation {
    width: 268px;
    background: #FCFBFC;
    padding-top: 36px;

    .nav-content {
        background: #FCFBFC;
        width: 100%;
    }

    .container {
        background: #FCFBFC;

    }
}

.nav-tab-active,
.nav-content-btn:hover {
    width: 100%;
    background: #F1F1F3;
    border-radius: 8px;
    border: solid 1px #F1F1F3;
    color: #111111 !important;

    span {
        color: #111111 !important;
    }
}

.kpi-cards {
    display: block;

    .kpi-card {
        margin-top: 25px;
    }
}

.best-creators {
    margin-top: 40px;

    .header-creators {
        display: flex;

        h4 {
            margin-right: auto;
            margin-top: auto;
            font-family: "HelveticaNowDisplay";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.03em;
            color: #A3A3A3;
        }

        button {}
    }

    .list-creators {
        >div {
            margin-top: 25px;
        }
    }
}

.main-content:not(.right-chat-active) {
    padding-right: 24px;
}

.text1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.03em;

    /* text[day] */

    color: #111111;
}

.text2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: -0.03em;

    /* text[day] */

    color: #111111;
}

.text3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.03em;

    /* gray[day] */

    color: #A3A3A3;
}

.text4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.03em;

    /* text[day] */

    color: #111111;
}

.header-create-admin-title {
    color: var(--background-night, #111);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-top: 12px;
    margin-bottom: 12px;
    align-items: center;
    margin-left: 16px;
}

input[type="checkbox"] {

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 2px solid #EEEEEE;
    background-color: white;
    outline: none;
    cursor: pointer;
    position: relative;
  }
input[type="checkbox"]:checked {
    border-color: #c80000;
    background-color: #c80000;
  }
  
  
input[type="checkbox"]::before {
    // content: "\2705";
    // content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: white;
    opacity: 0;
  
  }