.user-header-view {
    width: 100%;
    letter-spacing: -0.03em;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    gap: 4px;

    .avatar {
        display: inline-flex;
        align-items: center;
        height: 52px;
    
        img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
        }
    }

    h4 {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #A3A3A3;
        margin: 0px;
    }

    h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #111111;
        margin: 0px;
    }
}
